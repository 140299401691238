import { store } from '../store/store';

/// Wait for app dependencies to be loaded.
export default async function WaitForAppDependencies() {
  while (store.state.appDependenciesLoaded === false) {
    await new Promise(resolve => {
      setTimeout(() => {
        resolve(store.state.appDependenciesLoaded);
      }, 100);
    });
  }
}