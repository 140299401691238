import { vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "passwordInput" }
const _hoisted_2 = ["type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event)),
      onKeyup: _cache[1] || (_cache[1] = ($event: any) => {_ctx.$emit('update:modelValue', _ctx.password);}),
      maxlength: "100",
      type: _ctx.passwordInputType
    }, null, 40, _hoisted_2), [
      [_vModelDynamic, _ctx.password]
    ]),
    _createElementVNode("i", {
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleShowPassword && _ctx.toggleShowPassword(...args))),
      class: _normalizeClass(_ctx.toggleShowPasswordClass)
    }, null, 2)
  ]))
}