import { IStandardResponse, IStandardResponseError } from '@/models/StandardResponse';
import axios, { AxiosResponse } from 'axios';
import { i18n } from '@/plugins/i18n';
import ErrorUrlFromStatusCode from '@/services/ErrorUrlFromStatusCode';
interface WorkCallback<TResult extends IStandardResponse> {
  (): Promise<AxiosResponse<TResult>>;
}

export async function handleApiRequest<TResult extends IStandardResponse>(work: WorkCallback<TResult>): Promise<TResult> {
  try {
    const response = await work()
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        
        if (error.response.status !== 422) {
          window.location.href = ErrorUrlFromStatusCode(error.response.status);
        } 
        return error.response.data as unknown as TResult
      }
    }
    return { errors: [{ key: 'error_unhandled_exception', localizedName: i18n.global.t('error_unhandled_exception').toString() }] } as TResult;
  }
}