import axios from '@/api/axiosConfig';
import { store } from '@/store/store';
import { AppConfigResult } from '@/types/webContracts';
import { ApiRoutes } from '../ApiRoutes';
import IsNetworkProblem from '@/services/IsNetworkProblem';

export async function getAppConfig(): Promise<AppConfigResult> {
  let appConfigFailed = false;
  let networkError = false;
  let result = await axios.get(ApiRoutes.AppConfig.GetAppConfig)
    .then(response => {
      return response.data;
    })
    .catch(function (error) {
      store.state.error = error;
      appConfigFailed = true;
      if(IsNetworkProblem( error.response?.status, error.code))
        networkError=true;
    });

  if (result == null)
    result = {};

  result.appConfigFailed = appConfigFailed;
  result.networkError = networkError;
  
  return result;
}