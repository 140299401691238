<template>
  <div class="confirm-id">
    <CwePageHeader :authenticationRequired="true" />
    <div class="shared-page">
      <div class="centeredContentBox">
        <h1 class="header-left" role="heading">
          {{ $t("redeem_invite_title_confirm") }}
        </h1>

        <div v-if="pageErrorMessage">
          <p class="alertBox textLarge bottomSpaceDouble">
            {{ pageErrorMessage }}
          </p>
        </div>

        <!--                //we sent this to +1 xyz...&ndash;&gt;-->
        <p class="textLarge bottomSpace">
          {{ getVerificationCodeInstructions() }}
        </p>

        <form @submit.prevent="submitHandler">
            <div class="section">
                <div>
                    <input id="confirmInput"
                           v-model="phonePin"
                           maxlength="6"
                           type="text"
                           placeholder="xxxxxx"
                           @keypress.enter.prevent="submitHandler" />
                </div>
            </div>

            <p @click="sendVerificationCodeRequest"
               class="textLarge bottomSpaceDouble"
               v-html="getVerificationCodeHtml()"></p>

            <div class="button-section-right">
                <button class="button-right"
                        type="button"
                        value="ValidatePhonePin"
                        @click="submitHandler">
                    {{ $t('redeem_invite_validate_phone_button') }}
                </button>
                <button class="button-right go-back"
                        type="button"
                        @click="goBack">
                    {{ $t('go_back') }}
                </button>
            </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import * as Vue from "vue";
import CwePageHeader from "../components/CwePageHeader.vue";
import { handleApiRequest } from "../api/handleApiRequest";
import { sendVerificationCode, validateVerificationCode } from "../api/endpoints/authConfig";
import { parsePhoneNumber } from "libphonenumber-js";
import { store } from "../store";
import { SendActivationCodeMode, VerificationMode } from '../constants/enums';
import LoadAuthStatusAsync from '../services/AuthStatusLoader';


export default Vue.defineComponent({
  name: "ConfirmIdentityPage",
  components: {
    CwePageHeader,
  },
  data() {
    return {
      phonePin: "",
      guid: "",
      mode: "",
      userEmail: "",
      userPhone: "",
      displayPhoneNumber: false,
      verificationMode: VerificationMode.sms,
      pageErrorMessage: "",
    };
  },
  methods: {
    async submitHandler() {
      if (this.onBeforeSubmit()) {
        store.state.loadingCount++;

        const result = await handleApiRequest(() =>
          validateVerificationCode({
            RequestId: this.guid,
            SecurityCode: this.phonePin,
          })
        );

        if (!result.errors.length) {

          // Refresh policies and login status 
          await LoadAuthStatusAsync(false, true);

          const { mode, passwordExpired } = this.$router.currentRoute.value.query;

          if (mode == SendActivationCodeMode.change_password && passwordExpired == 'true') {
              this.$router.push({
                  name: "ChangePassword",
                  query: { passwordExpired: 'true' }
              });
          } else if (mode == SendActivationCodeMode.change_password) {
              this.$router.push({ name: "ChangePassword" });
          } else {
              this.$router.push({ name: "StartupPage" });
          }
        } else if(result.errors[0].key=='Error_CodeValidationAttemptsExceeded'){ 
          this.$router.push({
              name: "PreLoginPage", 
              query: { errorKey: 'Error_CodeValidationAttemptsExceeded' }
            });

        } else {
          if(result.errors[0].localizedName) 
            this.pageErrorMessage = result.errors[0].localizedName;
          else 
            this.pageErrorMessage = this.$t(result.errors[0].key);
        }

        store.state.loadingCount--;
      }
    },
    onBeforeSubmit() {
      let formState = true;
      this.phonePin = this.phonePin?.trim();
      if (this.phonePin == null || this.phonePin.length < 2) {
        this.pageErrorMessage = this.$t("validate_2fa_pin_invalid");
        formState = false;
      }
      return formState;
    },
    async sendVerificationCode() {
      this.clearErrors();

      const { mode, email } = this.$router.currentRoute.value.query;
      //malicious entry is inert. form renders but does nothing.
      if(!mode){ //email is optional 
          return false;
      }
      this.mode = mode?.toString();
      this.userEmail = email?.toString() ?? "";

      await this.sendVerificationCodeRequest();
    },
    goBack: function () {
      this.$router.back();
    },
    async sendVerificationCodeRequest(e?: Event) {
      if (e) {
        this.handleVerificationCodeRequestEvent(e);
      }
    
      store.state.loadingCount++;

      const result = await handleApiRequest(() =>
        sendVerificationCode({
          Mode: this.mode,
          Email: this.userEmail,
          VerificationMode: this.verificationMode
        })
      );

      if (result.errors) {
        if (result.errors.length === 0) {
          if (this.userEmail == null || this.userEmail.length == 0)
            this.userEmail = result.userEmail!;
                        
          if (!result.userPhoneNumber) {
            this.userPhone = "";
          } 
          else {
            this.processUserPhoneNumber(result.userPhoneNumber!);
          }

          this.displayPhoneNumber = result.displayPhoneNumber;
          this.verificationMode = this.displayPhoneNumber == false && this.userPhone.length == 0 ? VerificationMode.email : this.verificationMode;
          this.guid = result.requestId;

        } else if(result.errors[0].key=='Error_CodeValidationAttemptsExceeded'){ 
          this.$router.push({
              name: "PreLoginPage", 
              query: { errorKey: 'Error_CodeValidationAttemptsExceeded' }
            });

        } else {
          if(result.errors[0].localizedName) 
            this.pageErrorMessage = result.errors[0].localizedName;
          else 
            this.pageErrorMessage = this.$t(result.errors[0].key);
        }
      }

      store.state.loadingCount--;
    },
    handleVerificationCodeRequestEvent(e: Event) {
      e.preventDefault();

      if (e.target && e.target as Element) {
        var element = e.target as Element;

        if (element.classList.contains("verifyWithEmail")) {
          this.verificationMode = VerificationMode.email;
        }
        else if (element.classList.contains("verifyWithSMS")) {
          this.verificationMode = VerificationMode.sms;
        }
      }
    },
    processUserPhoneNumber(userPhoneNumber: string) {
      if (!this.userPhone && userPhoneNumber.toLowerCase().includes("xxxx")) {
        this.userPhone = userPhoneNumber;
      }
      else if (!this.userPhone && userPhoneNumber) {
        const national = parsePhoneNumber(userPhoneNumber).formatNational();
        const intlPrefix = parsePhoneNumber(userPhoneNumber).formatInternational().split(" ")[0];
        this.userPhone = intlPrefix + " " + this.maskPhoneNumber(national);
      }
    },
    getVerificationCodeInstructions() {
      if(this.verificationMode === VerificationMode.email) {
        return this.$t("validate_email_instructions_if_correct", [this.userEmail]);
      }
      else if (this.verificationMode === VerificationMode.sms) {
        if (this.displayPhoneNumber == false || this.userPhone.length == 0) {
          return this.$t("validate_phone_instructions_if_correct");
        }

        return this.$t("validate_phone_instructions_if_correct_with_number", [this.userPhone])  
      }
    },
    getVerificationCodeHtml() {
      let params = [
        '<span class="a-span tryAgain">',
        '</span>',
      ];
        
      if (this.displayPhoneNumber == false) {
        return this.$t('redeem_invite_validate_phone_didnt_receive', params).replace(",", "")
      }

      if(this.verificationMode === VerificationMode.email) {
        params.push('<span class="a-span verifyWithSMS">', '</span>')
        return this.$t('redeem_invite_validate_email_didnt_receive_or_verify_with_sms', params)
      }
              
      params.push('<span class="a-span verifyWithEmail">', '</span>')
      return this.$t('redeem_invite_validate_phone_didnt_receive_or_verify_with_email', params)
    },
    maskPhoneNumber(number: string) {
      return number.replace(/\d(?!\d{0,3}$)/g, "x");
    },
    clearErrors() {
      this.pageErrorMessage = "";
    },
  },
  created: function () {},

  mounted: function () {
    this.sendVerificationCode();
  },
});
</script>

<style lang="scss">
  .a-span {
    background: none !important;
    border: none;
    cursor: pointer;
    font-weight: $font_weight_bold;
    color: $cancel_button_text_color;
  }

  .a-span:hover {
    background: none !important;
    border: none;
    cursor: pointer;
    font-weight: $font_weight_bold;
    color: $cancel_button_text_color_hover;
  }

  #confirmInput {
    height: 2.5rem;
  }
</style>
