import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "error-page",
  class: "shared-page"
}
const _hoisted_2 = { class: "centeredContentBox" }
const _hoisted_3 = { class: "bottomSpace" }
const _hoisted_4 = { class: "textLarge bottomSpace" }
const _hoisted_5 = {
  key: 0,
  class: "textLarge bottomSpace"
}
const _hoisted_6 = { href: "/" }
const _hoisted_7 = {
  class: "button",
  type: "button",
  value: "Home"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CwePageHeader = _resolveComponent("CwePageHeader")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_CwePageHeader, { authenticationRequired: true }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t('error')), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t(_ctx.errorReason)), 1),
        ((_ctx.networkError))
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t('error_network')), 1))
          : _createCommentVNode("", true),
        _createElementVNode("a", _hoisted_6, [
          _createElementVNode("button", _hoisted_7, _toDisplayString(_ctx.$t('return_home')), 1)
        ])
      ])
    ])
  ]))
}