<template>
  <div id="mainPageWrap">
    <router-view v-show="store.state.loadingCount<=0 || store.state.initiated" />
  </div>
  <PageFooter />
  <PageOverlay v-bind:Loading="store.state.loadingCount>0" />
</template>

<script lang="ts">
  import * as Vue from 'vue';
  import PageFooter from './components/PageFooter.vue';
  import PageOverlay from './components/PageOverlay.vue';
  import { appConfigModule } from './store/appConfigModule';
  import { localizationModule } from './store/localizationModule';
  import { store } from './store/store';
  import GetBrowserLocale from './services/GetBrowserLocale';
  import { setupInactivityRedirect, handleLogout } from '@/services/IdleLogout';
  import EventBus from '@/services/EventBus';

  export default Vue.defineComponent({
    name: 'App',
    components: {
      PageOverlay,
      PageFooter
    },
    data() {
      return {
        Loading: false,
        Promises: [
          this.loadAppConfig(),
          this.loadLocalizationStrings()
        ],
        WindowFocused: true,
        store,
        networkError:false
      };
    },
    provide() {
      return {
        AppLoading: this.Loading,
      };
    },
    created: async function () {
      store.state.loadingCount++;

      window.addEventListener("beforeunload", function (event) {
        store.state.redirectBeforeRouteEnter = true;
      });

      EventBus.on('user-authenticated', setupInactivityRedirect);
      EventBus.on('user-logged-out', (redirect: any) => handleLogout(redirect));

      const results = await Promise.all(this.Promises.map(p => p.catch((e: Error) => e)));
      const errors = results?.filter(result => result instanceof Error) ?? [];

      store.state.loadingCount--;
      if (store.state.redirectBeforeRouteEnter == false) {
        let errorUrl = '/error?errorKey=';
        if (errors.length > 0 && !window.location.href.toLowerCase().includes(errorUrl.toLowerCase())) {
          let error = (errors.pop() as Error);
          errorUrl += error.message;

          if(this.networkError)
           errorUrl+='&networkError=true';

          window.location.href = errorUrl;
        }
      }

      store.state.appDependenciesLoaded = true;
    },
    methods: {
      loadAppConfig: async function () {
        if (store.state.aboutUrl == null || store.state.aboutUrl == '' ||
          store.state.supportUrl == null || store.state.supportUrl == '') {
          const loadAppConfigResult = await appConfigModule.loadAppConfig();

          if (!loadAppConfigResult || loadAppConfigResult.appConfigFailed) {
            if(loadAppConfigResult?.networkError)
              this.networkError=true;

            if (!window.location.href.includes('app_config_load_failed')) {
              throw Error('app_config_load_failed');
            }
          }
        }
      },
      loadLocalizationStrings: async function () {
        await this.loadBrowserLocale();

        const loadLocalizedStringsResult = await localizationModule.loadLocalizedStrings();

        if (!loadLocalizedStringsResult || loadLocalizedStringsResult.localizationLoadFailed) {
            if(loadLocalizedStringsResult?.networkError)
              this.networkError=true;

          if (!window.location.href.includes('localization_load_failed')) {
            throw Error('localization_load_failed');
          }
        }
      },
      loadBrowserLocale: async function () {
        store.state.browserLocale = GetBrowserLocale();
      }
    }
  });
</script>

<style lang="scss">
  select {
    background: url(@/assets/down.png) 96% / 15% no-repeat $light_gray;
    background-size: 20px;
  }

  #mainPageWrap { 
    display:flex;
    flex-direction:column;
    flex:2;
  }
</style>