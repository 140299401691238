import { store } from '@/store/store';
import { ChangePasswordResponse, GetUserResponse, UpdateUserRequest, UpdateUserResponse } from '@/types/webContracts';
import axios, { AxiosResponse } from 'axios';
import { ApiRoutes } from '../ApiRoutes';
import ErrorUrlFromStatusCode from '../../services/ErrorUrlFromStatusCode';

export async function getUser(): Promise<GetUserResponse> {
  store.state.loadingCount++;
  let userLoadFailed = false;
  let result = await axios.get(ApiRoutes.User.Profile)
      .then(response => {
        return response.data;
      })
      .catch(function (error) {
        store.state.error = error;
        userLoadFailed = true;
      }).finally(() => {
        store.state.loadingCount--;
      });

  if (result == null)
    result = {};

  result.userLoadFailed = userLoadFailed;
  return result;
}

function getCookieValue(cookieName: string) {
  const allCookies = decodeURIComponent(document.cookie).split('; ');

  for (let i = 0; i < allCookies.length; i++) {
    const cookie = allCookies[ i ];

    if (cookie.startsWith(cookieName + '=')) {
      return cookie.substring(cookieName.length + 1);
    }
  }

  return '';
}

export async function getAntiforgeryToken() {
  store.state.loadingCount++;
  const requestTokenResult = await axios.get(ApiRoutes.Auth.GetAntiForgeryToken)
      .then(response => {
        const requestToken = getCookieValue('XSRF-REQUEST-TOKEN');
        return requestToken;
      }).finally(() => {
        store.state.loadingCount--;
      });
  return requestTokenResult;
}

async function submitUserWithAntiforgeryToken(user: UpdateUserRequest, requestToken: any) {
  user.__RequestVerificationToken = requestToken;

  let result = await axios.post(ApiRoutes.User.Profile, user,
      {
        headers: { 'X-XSRF-TOKEN': requestToken }
      })
      .then(response => {
        return response.data;
      });

  if (result == null)
    result = {};

  return result;
}

async function submitUserProcess(user: UpdateUserRequest) {
  try {
    const r1 = await getAntiforgeryToken();
    const r2 = await submitUserWithAntiforgeryToken(user, r1);
    return r2;     // this will be the resolved value of the returned promise
  } catch (e) {
    console.log(e);
    throw e;      // let caller know the promise was rejected with this reason
  }
}

export async function submitUser(user: UpdateUserRequest): Promise<UpdateUserResponse> {
  store.state.loadingCount++;

  let response: any = null;
  const result = await submitUserProcess(user).then(result => {
    response = result;
  }).catch(error => {
    store.state.loadingCount = 0;

    if (error?.response?.status == 403 || error?.response?.status == 401)
      window.location.href = '/prelogin';
    else if (error?.response?.status != 422)
      window.location.href = ErrorUrlFromStatusCode(error?.response?.status);

    response = error?.response.data;
  }).finally(() => {
    store.state.loadingCount--;
  });

  return response;
}

export async function changePassword(password: string): Promise<AxiosResponse<ChangePasswordResponse>>{
  const response = await getAntiforgeryToken()
      .then(token => {
        return  axios.post(ApiRoutes.User.Password, {Password: password},
            {
              headers: { 'X-XSRF-TOKEN': token }
            });
      });
  return response;
}