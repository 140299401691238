import { store } from '@/store/store';
import { LocalizationResult } from '@/types/webContracts';
import { ApiRoutes } from '../ApiRoutes';
import axios from 'axios'
import axiosInstance from '@/api/axiosConfig';
import IsNetworkProblem from '@/services/IsNetworkProblem';

export async function getLocalizedStrings(): Promise<LocalizationResult> {
  let localizationLoadFailed = false;
  let networkError = false;
  let instance = axiosInstance;

  if (window.location.origin.includes('localhost'))
    instance = axios.create({
      baseURL: `${window.location.origin}`,
      timeout: 60000,
      headers: {
        'Content-Type': 'application/json; charset=UTF-8'
      }
    });

  let result = await instance.get(`${ApiRoutes.AppConfig.GetLocalizedStrings}`)
    .then(response => {
      return response.data as LocalizationResult;
    })
    .catch(error => {
      store.state.error = error;
      localizationLoadFailed = true;
      if( IsNetworkProblem( error.response?.status, error.code) )
        networkError=true;
    });

  if (result == null)
    result = {} as LocalizationResult;

  result.localizationLoadFailed = localizationLoadFailed;
  result.networkError = networkError;

  return result;
}