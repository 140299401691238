<template>
    <div class="password">
        <CwePageHeader :authenticationRequired="true"/>
        <div class="shared-page">
            <div class="centeredContentBox">
                <h1 class="header-left" role="heading">{{ $t('change_password') }}</h1>

                <p v-if="passwordExpired" class="textLarge bottomSpaceDouble">{{ $t('expired_reset_password_instructions') }}</p>
                <p v-else class="textLarge bottomSpaceDouble">{{ $t('forgot_reset_your_password_instructions') }}</p>

                <div v-if="errors.pageErrorMessage">
                    <p class="alertBox textLarge bottomSpaceDouble">{{ errors.pageErrorMessage }}</p>
                </div>


                <form @sumbit.prevent="submitHandler">
                    <div class="section">
                        <div class="label-bold">
                            <label for="password">{{ $t('redeem_invite_password') }}</label>
                        </div>

                        <div>
                            <password-input v-model="password" id="password"
                                            @keypress.enter.prevent="submitHandler" @blur="onBeforeSubmit" />
                        </div>

                        <div v-if="!errors.isMinLength" class="error">{{ lengthError() }}</div>
                    </div>

                    <div class="section bottomSpaceDouble">
                        <div class="label-bold">
                            <label for="confirmPassword">{{ $t('redeem_invite_confirm_password') }}</label>
                        </div>

                        <div>
                            <password-input v-model="confirmPassword" id="confirmPassword"
                                            @keypress.enter.prevent="submitHandler" @blur="onBeforeSubmit" />
                        </div>

                        <div v-if="errors.doesNotMatch" class="error">{{ matchError() }}</div>
                    </div>

                    <div class="button-section-right">
                        <button class="button-right" type="button" @click="submitHandler">
                            {{ $t('redeem_invite_save_password_button') }}
                        </button>
                        <button class="button-right go-back"
                                type="button"
                                @click="goBack">
                            {{ $t('go_back') }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import * as Vue from 'vue';
import CwePageHeader from '../components/CwePageHeader.vue';
import { store, sessionModule } from '../store';
import { changePassword } from '../api/endpoints/userWeb';
import { handleApiRequest } from '../api/handleApiRequest';
import PasswordInput from '../components/PasswordInput.vue';

export default Vue.defineComponent({
    name: 'ChangePasswordPage',
    components: {
        CwePageHeader,
        PasswordInput,
    },
    props: [],
    data() {
        return {
            password: '',
            confirmPassword: '',
            formValidationRan: false,
            errors: {
                isMinLength: true,
                doesNotMatch: false,
                pageErrorMessage: '',
            },
            passwordExpired: false
        };
    },
    computed: {
        confirmCurrentPassword() {
            return !sessionModule.initiatedPasswordResetFlow;
        }
    },
    methods: {
        matchError() {
            return this.$t('error_passwords_dont_match');
        },
        lengthError() {
            return this.$t('error_password_insufficient_length', [
                store.state.passwordMinLength.toString()
            ]);
        },
        async submitHandler() {
              this.formValidationRan = true

            if (this.onBeforeSubmit()) {
                store.state.loadingCount++;
                const result = await handleApiRequest(() =>
                      changePassword(this.password)
                );
                if (!result.errors.length) {
                    window.location.href = '/logout';
                } else {
                    this.errors.pageErrorMessage = result.errors[0].localizedName;
                }
                store.state.loadingCount--;
            }
        },
        goBack: function () {
            this.$router.back();
        },
        onBeforeSubmit() {
            //prevent this from running before the submit is clicked.
            if ( !this.formValidationRan) return;
            
            //check the model  
            this.resetErrors();
            let form_state = true;

            if (this.password == '') {
                form_state = false;
            }
            if (this.password.length < store.state.passwordMinLength) {
                this.errors.isMinLength = false;
                form_state = false;
            } else {
                this.errors.isMinLength = true;
            }
            //
            if (this.password !== this.confirmPassword) {
                form_state = false;
                this.errors.doesNotMatch = true;
            } else {
                this.errors.doesNotMatch = false;
            }
            return form_state;
        },
        resetErrors() {
            this.errors.isMinLength = true;
            this.errors.doesNotMatch = false;
            this.errors.pageErrorMessage = '';
        },
    },
    mounted(){
        this.passwordExpired = this.$route.query.passwordExpired == 'true';
    }
});
</script>

<style lang="scss" scoped>
</style>