import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  id: "RecoverPinPage",
  class: "shared-page"
}
const _hoisted_2 = { class: "centeredContentBox" }
const _hoisted_3 = { class: "back-section" }
const _hoisted_4 = { class: "back-section-inner" }
const _hoisted_5 = { class: "bottomSpaceDouble" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "alertBox textLarge bottomSpaceDouble" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "textLarge bottomSpace" }
const _hoisted_10 = { class: "section" }
const _hoisted_11 = { class: "label" }
const _hoisted_12 = ["for"]
const _hoisted_13 = { class: "bottomSpaceDouble" }
const _hoisted_14 = {
  key: 0,
  class: "error"
}
const _hoisted_15 = { class: "buttonWrap" }
const _hoisted_16 = {
  class: "button",
  type: "submit",
  value: "EmailFormSubmit"
}
const _hoisted_17 = { key: 2 }
const _hoisted_18 = { class: "section" }
const _hoisted_19 = { class: "buttonWrap" }
const _hoisted_20 = { class: "textLarge bottomSpace" }
const _hoisted_21 = { class: "textMed bottomSpace" }
const _hoisted_22 = { key: 0 }
const _hoisted_23 = { class: "textS bottomSpace" }
const _hoisted_24 = { class: "bottomSpace" }
const _hoisted_25 = { key: 1 }
const _hoisted_26 = { class: "textS bottomSpace" }
const _hoisted_27 = { class: "recoverPinButton" }
const _hoisted_28 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CwePageHeader = _resolveComponent("CwePageHeader")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_CwePageHeader, { authenticationRequired: true }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_ctx.currentStep == 'showPins')
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.back()))
                }, [
                  _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-angle-left" })
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t('recover_pin_title')), 1),
        (_ctx.pageErrorMessage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.pageErrorMessage), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.currentStep=='collectEmail')
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('recover_pin_email_instructions')), 1),
              _createElementVNode("form", {
                id: "ValidateEmailForm",
                onSubmit: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.SubmitEmail && _ctx.SubmitEmail(...args)))
              }, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("label", { for: _ctx.email }, _toDisplayString(_ctx.$t('email_address')), 9, _hoisted_12)
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event)),
                      maxlength: "100",
                      type: "email"
                    }, null, 512), [
                      [_vModelText, _ctx.email]
                    ])
                  ]),
                  (_ctx.emailError.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(_ctx.emailError), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("button", _hoisted_16, _toDisplayString(_ctx.$t('submit')), 1)
                ])
              ], 32)
            ]))
          : _createCommentVNode("", true),
        (_ctx.currentStep=='showPins')
          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("button", {
                    class: "button",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.SubmitEmail(null)))
                  }, "Refresh")
                ]),
                _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.$t('recover_pin_list_header')), 1),
                _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.$t('email_address')) + ": " + _toDisplayString(_ctx.email), 1),
                (_ctx.userVerificationRecoveryPin != null)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                      _createElementVNode("p", _hoisted_23, _toDisplayString(_ctx.$t('recover_pin_user_verification')), 1),
                      _createElementVNode("table", _hoisted_24, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('pin')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('expiration_time')), 1)
                        ]),
                        _createElementVNode("tr", null, [
                          _createElementVNode("td", null, _toDisplayString(_ctx.GetPin(_ctx.userVerificationRecoveryPin)), 1),
                          _createElementVNode("td", null, _toDisplayString(_ctx.GetExpirationTime(_ctx.userVerificationRecoveryPin)), 1)
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.inviteRecoveryPins != null && _ctx.inviteRecoveryPins.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                      _createElementVNode("p", _hoisted_26, _toDisplayString(_ctx.$t('recover_pin_invites')), 1),
                      _createElementVNode("table", null, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('pin')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('recover_pin_client')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('expiration_time')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('redeem_link')), 1)
                        ]),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.inviteRecoveryPins, (recoveryPin) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            key: recoveryPin.pin
                          }, [
                            _createElementVNode("td", null, _toDisplayString(_ctx.GetPin(recoveryPin)), 1),
                            _createElementVNode("td", null, _toDisplayString(recoveryPin.clientName), 1),
                            _createElementVNode("td", null, _toDisplayString(_ctx.GetExpirationTime(recoveryPin)), 1),
                            _createElementVNode("td", _hoisted_27, [
                              (recoveryPin.inviteId != undefined && recoveryPin.inviteId > 0)
                                ? (_openBlock(), _createElementBlock("button", {
                                    key: 0,
                                    onClick: ($event: any) => (_ctx.RedeemInvite(recoveryPin))
                                  }, _toDisplayString(_ctx.GetRecoverPinButtonText(recoveryPin)), 9, _hoisted_28))
                                : _createCommentVNode("", true)
                            ])
                          ]))
                        }), 128))
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}