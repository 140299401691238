
export default function ErrorUrlFromStatusCode(statusCode:any){

  // convert int to string 
  statusCode = statusCode+'';

  let key = 'unexpected';
  switch(statusCode)
  {
    case '502': 
    case '503': 
    case '504':
      key='error_network';
      break;
    case '500':
      key='exception';
      break;
    case '422':
      key='unprocessable_entity';
      break;
    case '404':
      key='page_not_found';
      break;
    case '403':
      key='insufficient_permissions';
      break;
  }

  return '/error?errorKey='+key;
}

