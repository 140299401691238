export enum AuthWorkFlow {
  None = 'None',
  ChangePassword = 'ChangePassword'
}

export enum SendActivationCodeMode {
  unknown = 'unknown',
  change_password = 'change_password',
  identity_validation = 'identity_validation'
}

export enum VerificationMode {
    email = 'email',
    sms = 'sms'
}