<template>
  <div>
    <CwePageHeader :authenticationRequired="true" :isApp="isApp == true" />

    <div id="PolicyPage" class="shared-page">
      <link rel="stylesheet" href="/css/policy-styles.css">

      <div class="centeredContentBox centeredContentBoxMedia">
        <div v-if="htmlErrorMessage" class="alertBox bottomSpace" v-html="htmlErrorMessage"></div>

        <div v-else class="policy-section">
            <h4>{{ policyHeader }}</h4>
            <div v-html="policyText" class="policyWrapper"></div>
        </div>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
  import { GetPolicy } from "@/api/endpoints/policyWeb";
  import { GetPolicyRequest } from '@/types/webContracts';
  import * as Vue from 'vue';
  import CwePageHeader from '../components/CwePageHeader.vue';
  import WaitForAppDependencies from '../services/AppDependencyService';
  export default Vue.defineComponent({
    name: 'PolicyPage',
    components: {
      CwePageHeader,
    },
    data() {
      return {
        policyKey: '',
        policyHeader: '',
        policyText: '',
        isApp: false,
        htmlErrorMessage: ''
      }
    },
    created: async function () {
      await WaitForAppDependencies();
      await this.loadPolicy();
    },
    methods: {
      async loadPolicy() {
        const { policyKey, isApp } = this.$router.currentRoute.value.query;

        //malicious entry is inert.
        if (!policyKey) {
          return false;
        }

        this.policyKey = policyKey?.toString();
        this.isApp = isApp?.toString() === "true";

        if (this.isApp == true)
          document.body.classList.add("showMobile");

        await this.performLoadPolicy(this.policyKey);
      },
      performLoadPolicy: async function (policyKey: string) {
        const policyRequest: GetPolicyRequest = {
          policyKey: policyKey
        };

        const result = await GetPolicy(policyRequest);

        if (result.status == 403 || result.status == 401) {
          this.htmlErrorMessage = this.$t('unexpected_error');
          window.scrollTo(0, 0);
        }
        else if (result?.data?.errors != null && result.data.errors.length > 0) {
          this.htmlErrorMessage = result.data.errors[0].localizedName ?? this.$t('unexpected_error');
          window.scrollTo(0, 0);
        }
        else if (result.data?.policies == null) {
          this.htmlErrorMessage = this.$t('unexpected_error');
          window.scrollTo(0, 0);
        }

        if (this.policyKey == "App_End_User_License_Agreement") {
          const eulaPolicy = result.data.policies[0];

          this.policyHeader = eulaPolicy.header;
          this.policyText = eulaPolicy.description;
        }
        else if (this.policyKey == "Privacy_Policy") {
          const policy = result.data.policies[0];
          this.policyHeader = policy.header;
          this.policyText = policy.description;
        }
      }
    },
  })
</script>
<style lang="scss">
  #PolicyPage {
    text-align: left;
    font-size: (1200px / $maxInnerPageWidth) * 1rem;
    padding-left: (1200px / $maxInnerPageWidth) * 5%;
    padding-right: (1200px / $maxInnerPageWidth) * 5%;
    padding-top: (1200px / $maxInnerPageWidth) * 5%;

    .centeredContentBox {
      width: 80%;
      max-width: $maxInnerPageWidth;
      background: none;
      padding: 0;
    }

    h4 {
      color: #000000;
    }
  }

  @media screen and ( max-width: 960px ) {
    #PolicyPage {
      font-size: (960px / $maxInnerPageWidth) * 1.25rem;
      padding-left: (960px / $maxInnerPageWidth) * 5%;
      padding-right: (960px / $maxInnerPageWidth) * 5%;
      padding-top: (960px / $maxInnerPageWidth) * 5%;

      .centeredContentBoxMedia {
        padding-top: (960px / $maxInnerPageWidth) * 5%;
        margin-top: (960px / $maxInnerPageWidth) * 5%;
        margin-left: 0;
        width: 100%;
        max-width: $maxInnerPageWidth;
      }
    }
  }

  @media screen and ( max-width: 720px ) {
    #PolicyPage {
      font-size: (720px / $maxInnerPageWidth) * 1.5rem;
      padding-left: (720px / $maxInnerPageWidth) * 5%;
      padding-right: (720px / $maxInnerPageWidth) * 5%;
      padding-top: (720px / $maxInnerPageWidth) * 5%;

      .centeredContentBoxMedia {
        padding-top: (720px / $maxInnerPageWidth) * 5%;
        margin-top: (720px / $maxInnerPageWidth) * 5%;
      }
    }
  }

  @media screen and ( max-width: 600px ) {
    #PolicyPage {
      font-size: (600px / $maxInnerPageWidth) * 1.5rem;
      padding-left: (600px / $maxInnerPageWidth) * 5%;
      padding-right: (600px / $maxInnerPageWidth) * 5%;
      padding-top: (600px / $maxInnerPageWidth) * 5%;

      .centeredContentBoxMedia {
        padding-top: (600px / $maxInnerPageWidth) * 5%;
        margin-top: (600px / $maxInnerPageWidth) * 5%;
      }
    }

    .showMobile {
      h4 {
        font-size: (600px / $maxInnerPageWidth) * 2rem;
        color: #000000;
      }

      a {
        word-wrap: break-word;
      }
    }
  }
</style>
