<template>
  <div>
    <CwePageHeader :authenticationRequired="true" />

    <div class="shared-page">

      <div class="centeredContentBox">
        <div id="logoWrap">ZB<span id="logoEdgeWrap"><span id="logoEWrap">E</span>dge</span>&nbsp;</div>

        <p class="textLarge bottomSpaceDouble">{{ $t('page_not_found') }}</p>

        <router-link :to="{name:'Dashboard'}">
          <button class="button" type="button" value="Home">{{ $t('return_home') }}</button>
        </router-link>
      </div>

    </div>
  </div>

</template>

<script lang="ts">
  import CwePageHeader from '../components/CwePageHeader.vue';
  import FixProxyUrl from '../services/FixProxyUrl';

  export default {
    name: 'NotFoundPage',
    components: {
      CwePageHeader,
    },
    data() {
      return {
      }
    },
    provide() {
      return {

      }
    },
    methods: {
      FixProxyAuthUrl(path: string) {
        return FixProxyUrl(path);
      }
    }
  }
</script>

<style lang="scss">
  .shared-page #logoWrap {
    margin-bottom: 20px;
  }
</style>
