import { i18n } from '@/plugins/i18n';
import { LocalizationState } from '@/types/storeTypes';
import { LocaleMessageDictionary, VueMessageType } from 'vue-i18n';
import { Action, Module, Mutation, VuexModule } from 'vuex-class-modules';
import { getLocalizedStrings } from '../api/endpoints/localizationWeb';
import { LocalizationResult } from '@/types/webContracts';
import { store } from './store';

@Module
class LocalizationModule extends VuexModule implements LocalizationState {
  locale = '';
  languageRegion = 'en-US';
  region = 'US';
  defaultLocale = 'en-US';
  loaded = false;
  errorPageStrings = {
    'about': 'About',
    'app_config_load_failed': 'Unable to load app configuration.',
    'countries_load_failed': 'Unable to load countries.',
    'error': 'Error',
    'error_processing_request': 'An error occurred while processing your request.',
    'error_unhandled_exception': 'An unhandled error occurred.',
    'localization_load_failed': 'Unable to load translations.',
    'gen_error_system_issue': 'It looks like we are having a system issue. Please try again or visit us again soon.',
    'insufficient_permissions': 'Insufficient permissions to access this resource.',
    'support': 'Support',
    'return_home': 'Return Home',
    'session_timeout': 'Session timeout. Please log back in.',
    'unexpected_error': 'An unexpected error occurred.'
  };

  @Mutation
  setLocaleMutation(locale: string) {
    this.locale = locale;
  }

  @Mutation
  setRegionMutation(region: string) {
    this.region = region;
  }

  @Mutation
  setLanguageRegionMutation(languageRegion: string) {
    this.languageRegion = languageRegion;
  }

  @Mutation
  setLoaded(isLoaded: boolean) {
    this.loaded = isLoaded;
  }

  @Action
  setLocale(locale: string) {
    this.setLocaleMutation(locale);
  }

  @Action
  async loadLocalizedStrings(): Promise<LocalizationResult> {
    this.loadDefaultLocalization();

    const result = await getLocalizedStrings();

    if (result != null && result.locale != null && !result.localizationLoadFailed) {
      const messages = result.phrases as LocaleMessageDictionary<VueMessageType>;
      i18n.global.setLocaleMessage(result.locale.split('-')[0], messages);
      i18n.global.locale = result.locale;
      this.setLocale(result.locale);
      this.setLoaded(true);
    }

    return result;
  }

  @Action
  loadDefaultLocalization() {
    const messages = this.errorPageStrings as object as LocaleMessageDictionary<VueMessageType>;
    i18n.global.setLocaleMessage(this.defaultLocale.split('-')[0], messages);
    i18n.global.locale = this.defaultLocale;
    this.setLocale(this.defaultLocale);
  }
}

export const localizationModule = new LocalizationModule({
  store,
  name: 'localization'
});