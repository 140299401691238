import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "phoneNumberLabelRow" }
const _hoisted_2 = { class: "phoneNumberLabelColumn" }
const _hoisted_3 = { class: "label-bold" }
const _hoisted_4 = ["for"]
const _hoisted_5 = { class: "phoneNumberRow" }
const _hoisted_6 = { class: "phoneCountryCodeColumn" }
const _hoisted_7 = { class: "phoneCountryCode" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "countryCode" }
const _hoisted_10 = {
  key: 1,
  class: "ext"
}
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "countryCode" }
const _hoisted_13 = {
  key: 0,
  class: "ext"
}
const _hoisted_14 = { class: "phoneNumberColumn" }
const _hoisted_15 = ["value", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_multiselect = _resolveComponent("vue-multiselect")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", { for: _ctx.phoneNumber }, _toDisplayString(_ctx.phoneNumberLabel), 9, _hoisted_4)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_vue_multiselect, {
            id: "countryCodeSelect",
            placeholder: "",
            modelValue: _ctx.selectedCountryCode,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCountryCode) = $event)),
            options: _ctx.phoneCountryCodes,
            onRemove: _ctx.clearButtonSelected,
            "track-by": "Code",
            label: "Code",
            "option-height": 30,
            "max-height": 300,
            "show-labels": false,
            onSelect: _ctx.phoneCountryCodeChanged
          }, {
            singleLabel: _withCtx((props) => [
              (_ctx.selectedCountryCode?.Code != '')
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: "option__image",
                    src: _ctx.getFlag(props?.option?.Code)
                  }, null, 8, _hoisted_8))
                : _createCommentVNode("", true),
              _createTextVNode("   "),
              _createElementVNode("span", _hoisted_9, _toDisplayString(props?.option?.Code), 1),
              (props.option.Ext != '')
                ? (_openBlock(), _createElementBlock("span", _hoisted_10, "(" + _toDisplayString(props?.option?.Ext) + ")", 1))
                : _createCommentVNode("", true)
            ]),
            option: _withCtx((props) => [
              _createElementVNode("img", {
                class: "option__image",
                src: _ctx.getFlag(props?.option?.Code)
              }, null, 8, _hoisted_11),
              _createTextVNode("  "),
              _createElementVNode("span", _hoisted_12, _toDisplayString(props?.option?.Code), 1),
              (props.option.Ext != '')
                ? (_openBlock(), _createElementBlock("span", _hoisted_13, "(" + _toDisplayString(props?.option?.Ext) + ")", 1))
                : _createCommentVNode("", true)
            ]),
            noResult: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.noMatchingCountriesLabel), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "options", "onRemove", "onSelect"])
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("input", {
          id: "phoneNumber",
          value: _ctx.phoneNumber,
          placeholder: _ctx.placeholder,
          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.phoneChanged($event.target.value)))
        }, null, 40, _hoisted_15)
      ])
    ])
  ], 64))
}