<template>

  <div id="StartupPage">
    
  </div>

</template>

<script lang="ts">
import * as Vue from 'vue'

export default Vue.defineComponent({
  name: 'StartupPage',
  components: {
    
  },
  data(){
    return {
    }
  },
  provide() {
    return {
      
    }
  },
  methods:{
    
  }
})
</script>

<style lang="scss">
#StartupPage {
    background: $white;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 1000;
}
</style>