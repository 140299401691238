

export default function IsNetworkProblem(statusCode:any, errorCode:string){

  // convert int to string 
  statusCode = statusCode+'';

  return (statusCode=='502' || statusCode=='503' || statusCode=='504' || errorCode=='ERR_NETWORK')
}

