import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_DOMAIN,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8'
  }
});

export default axiosInstance