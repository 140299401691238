import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, vModelText as _vModelText } from "vue"

const _hoisted_1 = {
  id: "RedeemInvitePage",
  class: "shared-page"
}
const _hoisted_2 = { class: "centeredContentBox" }
const _hoisted_3 = { class: "back-section" }
const _hoisted_4 = { class: "back-section-inner" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "alertBox textLarge bottomSpaceDouble" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "textLarge bottomSpace" }
const _hoisted_9 = { class: "section" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "buttonWrap" }
const _hoisted_12 = {
  class: "button",
  type: "submit",
  value: "ValidatePhonePin"
}
const _hoisted_13 = { key: 2 }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = {
  key: 1,
  class: "buttonWrap bottomSpaceDouble"
}
const _hoisted_16 = {
  class: "button",
  type: "button",
  value: "Register"
}
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = {
  key: 3,
  class: "textLarge bottomSpaceDouble"
}
const _hoisted_19 = {
  key: 4,
  class: "textLarge bottomSpaceDouble"
}
const _hoisted_20 = {
  key: 5,
  class: "textLarge bottomSpaceDouble"
}
const _hoisted_21 = { class: "buttonWrap bottomSpaceDouble" }
const _hoisted_22 = ["href"]
const _hoisted_23 = {
  class: "button",
  type: "button",
  value: "Login"
}
const _hoisted_24 = {
  key: 6,
  class: "textLarge bottomSpaceDouble"
}
const _hoisted_25 = {
  key: 7,
  class: "buttonWrap"
}
const _hoisted_26 = {
  class: "button",
  type: "button",
  value: "Register"
}
const _hoisted_27 = { key: 3 }
const _hoisted_28 = {
  key: 0,
  class: "textLarge bottomSpaceDouble"
}
const _hoisted_29 = {
  key: 0,
  class: "section"
}
const _hoisted_30 = { class: "label-bold" }
const _hoisted_31 = ["for"]
const _hoisted_32 = {
  key: 0,
  class: "error"
}
const _hoisted_33 = {
  key: 1,
  class: "section"
}
const _hoisted_34 = { class: "label-bold" }
const _hoisted_35 = ["for"]
const _hoisted_36 = {
  key: 0,
  class: "error"
}
const _hoisted_37 = {
  key: 2,
  class: "section bottomSpaceDouble"
}
const _hoisted_38 = {
  key: 0,
  class: "error"
}
const _hoisted_39 = { class: "button-section-right" }
const _hoisted_40 = {
  class: "button-right",
  type: "submit",
  value: "Save Password"
}
const _hoisted_41 = {
  key: 4,
  id: "proceedToLoginWrap",
  class: "button-section-right"
}
const _hoisted_42 = { class: "textLarge bottomSpaceDouble center" }
const _hoisted_43 = ["href"]
const _hoisted_44 = {
  class: "button-center",
  type: "button",
  value: "Login"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CwePageHeader = _resolveComponent("CwePageHeader")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_password_input = _resolveComponent("password-input")!
  const _component_PhoneNumberInput = _resolveComponent("PhoneNumberInput")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_CwePageHeader, { authenticationRequired: true }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (!_ctx.phoneVerified && !_ctx.invalidInvite && !_ctx.registrationFormShown && _ctx.phoneWasMissing == true)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.back()))
                }, [
                  _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-angle-left" })
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _withDirectives(_createElementVNode("h3", { class: "bottomSpaceDouble header-left" }, _toDisplayString(_ctx.GetPageTitle()), 513), [
          [_vShow, _ctx.initiated]
        ]),
        (_ctx.pageErrorMessage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.pageErrorMessage), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.pageMode=='validatePhone')
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('redeem_invite_validate_phone_instructions', [_ctx.formattedMaskedPhone])), 1),
              _createElementVNode("form", {
                id: "ValidatePhoneForm",
                onSubmit: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.ValidatePhonePin && _ctx.ValidatePhonePin(...args)))
              }, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.phonePin) = $event)),
                      maxlength: "6",
                      type: "text",
                      placeholder: "xxxxxx"
                    }, null, 512), [
                      [_vModelText, _ctx.phonePin]
                    ])
                  ])
                ]),
                _createElementVNode("p", {
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.resendInvite && _ctx.resendInvite(...args))),
                  class: "textLarge bottomSpaceDouble",
                  innerHTML: _ctx.$t('redeem_invite_validate_phone_didnt_receive', ['<a href=\'#\'>', '</a>']).replace(',','')
                }, null, 8, _hoisted_10),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("button", _hoisted_12, _toDisplayString(_ctx.$t('redeem_invite_validate_phone_button')), 1)
                ])
              ], 32)
            ]))
          : _createCommentVNode("", true),
        (_ctx.pageMode=='newOrExistingUserSelector')
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              (_ctx.redeemInviteUserTypeMode=='migrating')
                ? (_openBlock(), _createElementBlock("p", {
                    key: 0,
                    class: "textLarge bottomSpaceDouble alertBox",
                    innerHTML: _ctx.$t('redeem_invite_body_migrated_migrate')
                  }, null, 8, _hoisted_14))
                : _createCommentVNode("", true),
              (_ctx.redeemInviteUserTypeMode=='migrating' && _ctx.allowRegister)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createElementVNode("a", {
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.Register()))
                    }, [
                      _createElementVNode("button", _hoisted_16, _toDisplayString(_ctx.$t('redeem_invite_register_migrated_button')), 1)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.redeemInviteUserTypeMode=='migrating' && _ctx.allowRegister)
                ? (_openBlock(), _createElementBlock("p", {
                    key: 2,
                    class: "textLarge bottomSpaceDouble",
                    innerHTML: _ctx.$t('redeem_invite_body_migrated_login')
                  }, null, 8, _hoisted_17))
                : _createCommentVNode("", true),
              (_ctx.redeemInviteUserTypeMode=='optivu')
                ? (_openBlock(), _createElementBlock("p", _hoisted_18, _toDisplayString(_ctx.$t('redeem_invite_body_optivu')), 1))
                : _createCommentVNode("", true),
              (_ctx.redeemInviteUserTypeMode=='zbemployees')
                ? (_openBlock(), _createElementBlock("p", _hoisted_19, _toDisplayString(_ctx.$t('redeem_invite_body_zb_employees')), 1))
                : _createCommentVNode("", true),
              (_ctx.redeemInviteUserTypeMode=='default')
                ? (_openBlock(), _createElementBlock("p", _hoisted_20, _toDisplayString(_ctx.$t('redeem_invite_body_login')), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("a", {
                  href: _ctx.Login(true)
                }, [
                  _createElementVNode("button", _hoisted_23, _toDisplayString(_ctx.$t('redeem_invite_login_button')), 1)
                ], 8, _hoisted_22)
              ]),
              (_ctx.redeemInviteUserTypeMode=='default' && _ctx.allowRegister)
                ? (_openBlock(), _createElementBlock("p", _hoisted_24, _toDisplayString(_ctx.$t('redeem_invite_body_register')), 1))
                : _createCommentVNode("", true),
              (_ctx.redeemInviteUserTypeMode!='migrating' && _ctx.allowRegister)
                ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                    _createElementVNode("a", {
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.Register()))
                    }, [
                      _createElementVNode("button", _hoisted_26, _toDisplayString(_ctx.$t('redeem_invite_register_button')), 1)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.pageMode=='registerNewAccount')
          ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
              (!_ctx.isFederatedUser)
                ? (_openBlock(), _createElementBlock("p", _hoisted_28, _toDisplayString(_ctx.$t('redeem_invite_register_body')), 1))
                : _createCommentVNode("", true),
              _createElementVNode("form", {
                id: "createAccountForm",
                onSubmit: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.SubmitPasswordPhone && _ctx.SubmitPasswordPhone(...args)))
              }, [
                (!_ctx.isFederatedUser)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                      _createElementVNode("div", _hoisted_30, [
                        _createElementVNode("label", { for: _ctx.password }, _toDisplayString(_ctx.$t('redeem_invite_password')), 9, _hoisted_31)
                      ]),
                      _createElementVNode("div", null, [
                        _createVNode(_component_password_input, {
                          modelValue: _ctx.password,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.password) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      (_ctx.passwordError.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_32, _toDisplayString(_ctx.passwordError), 1))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                (!_ctx.isFederatedUser)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                      _createElementVNode("div", _hoisted_34, [
                        _createElementVNode("label", { for: _ctx.confirmPassword }, _toDisplayString(_ctx.$t('redeem_invite_confirm_password')), 9, _hoisted_35)
                      ]),
                      _createElementVNode("div", null, [
                        _createVNode(_component_password_input, {
                          modelValue: _ctx.confirmPassword,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.confirmPassword) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      (_ctx.confirmPasswordError.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_36, _toDisplayString(_ctx.confirmPasswordError), 1))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.maskedPhone == null || _ctx.maskedPhone?.length == 0 || _ctx.phoneFromRegistration == true)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                      _createVNode(_component_PhoneNumberInput, {
                        phoneNumberLabel: this.$t('mobile_number'),
                        noMatchingCountriesLabel: this.$t('no_matching_countries'),
                        phoneCountryCodes: this.phoneCountryCodes,
                        phoneCountryCode: this.selectedCountryCode,
                        phoneNumber: this.phone,
                        onUpdatePhoneCountryCode: _cache[8] || (_cache[8] = ($event: any) => (_ctx.phoneCountryCodeChanged($event))),
                        onUpdatePhoneNumber: _cache[9] || (_cache[9] = ($event: any) => (_ctx.phoneNumberChanged($event))),
                        onUpdatePhoneNumberError: _cache[10] || (_cache[10] = ($event: any) => (_ctx.phoneNumberErrorChanged($event)))
                      }, null, 8, ["phoneNumberLabel", "noMatchingCountriesLabel", "phoneCountryCodes", "phoneCountryCode", "phoneNumber"]),
                      (_ctx.phoneError.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_38, _toDisplayString(_ctx.phoneError), 1))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_39, [
                  _createElementVNode("button", _hoisted_40, _toDisplayString(_ctx.isFederatedUser ? _ctx.$t('redeem_invite_validate_phone_button') : _ctx.$t('redeem_invite_save_password_button')), 1),
                  _createElementVNode("button", {
                    class: "button-right go-back",
                    type: "button",
                    onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.backToRegisterOrLogin && _ctx.backToRegisterOrLogin(...args)))
                  }, _toDisplayString(_ctx.$t('go_back')), 1)
                ])
              ], 32)
            ]))
          : _createCommentVNode("", true),
        (_ctx.pageMode=='proceedToLogin')
          ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
              _createElementVNode("p", _hoisted_42, _toDisplayString(_ctx.$t('redeem_invite_register_success')), 1),
              _createElementVNode("a", {
                href: _ctx.Login(false)
              }, [
                _createElementVNode("button", _hoisted_44, _toDisplayString(_ctx.$t('redeem_invite_proceed_to_login_button')), 1)
              ], 8, _hoisted_43)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}