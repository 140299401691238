import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "mainPageWrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_PageFooter = _resolveComponent("PageFooter")!
  const _component_PageOverlay = _resolveComponent("PageOverlay")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createVNode(_component_router_view, null, null, 512), [
        [_vShow, _ctx.store.state.loadingCount<=0 || _ctx.store.state.initiated]
      ])
    ]),
    _createVNode(_component_PageFooter),
    _createVNode(_component_PageOverlay, {
      Loading: _ctx.store.state.loadingCount>0
    }, null, 8, ["Loading"])
  ], 64))
}