<template>
    <div v-if="Loading" id="PageOverlay">
        <div v-if="Loading" class="spinner"></div>
    </div>
</template>

<script lang="ts">
import * as Vue from 'vue'

export default Vue.defineComponent({
  name: 'PageOverlay',
  props: {
    Loading: Boolean,
  },
  components: {
    
  },
  data() {
    return { 

    }
  },
  methods:{
      
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#PageOverlay {
    background: rgba(0,0,0,.5);
    position: fixed;
    height: 100vh;
    width: 100vw;
    top:0;
    left:0;
    z-index: 1000;
}

.spinner {
   position: absolute;
   left: 50%;
   top: 50%;
   height:60px;
   width:60px;
   margin:-30px 0 0 -30px;
   -webkit-animation: rotation .6s infinite linear;
   -moz-animation: rotation .6s infinite linear;
   -o-animation: rotation .6s infinite linear;
   animation: rotation .6s infinite linear;
   border-left:6px solid rgba(255, 255, 255, .30);
   border-top:6px solid  rgba(255, 255, 255, .50);
   border-right:6px solid rgba(255, 255, 255, .10);
   border-bottom:6px solid rgba(255, 255, 255, .20);
   border-radius:100%;
}

@-webkit-keyframes rotation {
   from {-webkit-transform: rotate(0deg);}
   to {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
   from {-moz-transform: rotate(0deg);}
   to {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
   from {-o-transform: rotate(0deg);}
   to {-o-transform: rotate(359deg);}
}
@keyframes rotation {
   from {transform: rotate(0deg);}
   to {transform: rotate(359deg);}
}

</style>
