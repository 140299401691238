import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "MoreAppListItem" }
const _hoisted_2 = { class: "col-content" }
const _hoisted_3 = { class: "appLogo" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "appInfo" }
const _hoisted_6 = { class: "appName" }
const _hoisted_7 = { class: "appDesc" }
const _hoisted_8 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: _ctx.app.iconUrl,
          alt: _ctx.pic
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.app.name), 1),
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.app.description), 1),
        _createElementVNode("a", {
          href: _ctx.app.learnMoreUrl,
          target: "_blank"
        }, _toDisplayString(_ctx.$t('learn_more')), 9, _hoisted_8)
      ])
    ])
  ]))
}