<template>
    <div class="MoreAppListItem">
        <div class="col-content">
            <div class="appLogo">
                <img :src="app.iconUrl" v-bind:alt="pic">
            </div>
            <div class="appInfo">
                <div class="appName">{{ app.name }}</div>
                <div class="appDesc">{{ app.description }}</div>
                <a :href="app.learnMoreUrl" target="_blank">{{ $t('learn_more') }}</a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import AppRedirectService from '@/services/AppRedirectService';
import * as Vue from 'vue';

export default Vue.defineComponent({
    name: 'MoreAppListItem',
    props: {
        app: Object
    },
	methods: {

    }
});
</script>


<style lang="scss">

	.MoreAppListItem {
		display: inline-block;
		margin: 0 0 $standard_space 0;
		flex: 0 0 auto;
		padding: 0;

		.col-content {
			background: #fff;
			border-radius: $element_border_radius;
			padding: $standard_space / 2;
			outline: 1px solid $light_gray;
			min-height: 210px;
			height: 100%;
			position: relative;
			display: flex;

			a {
				font-size: 0.9rem;
				color: $primary_color;
				font-weight: bold;
				margin-top: auto;
				cursor: pointer;
			}

			.appInfo {
				margin-top: $standard_space;
				margin-left: $standard_space * 2;
				display: flex;
				flex-direction: column;
			}

			.appLogo {
				width: 20%;
				text-align: center;
				margin-top: $standard_space;
				margin-left: $standard_space;

				img {
					width: 84px;
					height: 84px;
					border-radius: 6px;
				}
			}
		}

		.appName {
			font-weight: bold;
			margin-bottom: 5px;
		}

		.appDesc {
			font-size: 0.9rem;
			margin-bottom: $standard_space / 4;
		}


		@media all and (max-width: 700px) {
			.col-content {
				min-height: unset;
				padding: $standard_space / 2;

				.appInfo {
					margin-top: 0; //$standard_space / 2;
					margin-left: 0; //$standard_space / 2;
				}

				.appLogo {
					width: 64px;
					box-sizing: border-box;
					margin-top: 0; //$standard_space / 2;
					margin-left: 0; //$standard_space / 2;
					margin-right: $standard_space / 2;
					margin-bottom: 0; //$standard_space / 2;

					img {
						width: 64px;
						height: 64px;
						//max-width:100%;
					}
				}
			}
		}

		@media all and (max-width: 575px) {
			min-width: 100%;

			.col-content .appLogo {
				width: 48px;

				img {
					width: 48px;
					height: 48px;
				}
			}
		}
	}
</style>