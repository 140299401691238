import axios from '@/api/axiosConfig';
import { ApiRoutes } from '../ApiRoutes';
import { store } from '@/store/store';
import { CountryResult } from '@/types/webContracts';
import IsNetworkProblem from '@/services/IsNetworkProblem';

export async function getCountries(): Promise<CountryResult> {
  let countriesLoadFailed = false;
  let networkError = false;
  let result = await axios.get(ApiRoutes.AppConfig.GetCountries)
    .then(response => {
      return response.data as CountryResult;
    })
    .catch(function (error) {
      store.state.error = error;
      countriesLoadFailed = true;
      if(IsNetworkProblem( error.response?.status, error.code))
        networkError=true;
    });

  if (result == null)
    result = {} as CountryResult;

  result.countriesLoadFailed = countriesLoadFailed;
  result.networkError = networkError;
  return result;
}