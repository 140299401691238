<template>
  <div>
    <CwePageHeader :authenticationRequired="true" />

    <div id="maintenance-page" class="shared-page">
      <div class="centeredContentBox">
        <font-awesome-icon icon="fa-solid fa-triangle-person-digging"></font-awesome-icon>

        <p class="textLarge bottomSpace" v-html="$t('error_outage_try_later')"></p>
      </div>
    </div>
  </div>

</template>

<script lang="ts">
  import * as Vue from 'vue';
  import CwePageHeader from '../components/CwePageHeader.vue';


  export default Vue.defineComponent({
    name: 'MaintenancePage',
    components: {
      CwePageHeader,
    },
    data() {
      return {
        messageKey: ''
      }
    },
    provide() {
      return {

      }
    },
    created() {
      
    }
  })
</script>

<style lang="scss">
  #maintenance-page {
    font-size: $font_size_button_text;

    .centeredContentBox {
      padding-top:40px;
      padding-bottom:40px;
    }

    svg {
        display: block;
        min-width:100px;
        min-height:100px;
        margin:auto;
        margin-bottom: 20px;
    }
  }
</style>
