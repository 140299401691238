<template>
  <div>
    <CwePageHeader :authenticationRequired="true" />

    <div class="shared-page">

      <div class="centeredContentBox">
        <div id="logoWrap" class="bottomSpaceDouble">ZB<span id="logoEdgeWrap"><span id="logoEWrap">E</span>dge</span>&nbsp;</div>

        <p id="welcomeText" class="bottomSpaceDouble">
          {{ $t('welcome_text') }}
        </p>

        <div v-if="errorDisplayKey" class="alertBox bottomSpace">{{ $t(errorDisplayKey) }}</div>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
  import * as Vue from 'vue';
  import CwePageHeader from '../components/CwePageHeader.vue';
  import FixProxyUrl from '../services/FixProxyUrl';

  export default Vue.defineComponent({
    name: 'SignUpPage',
    components: {
      CwePageHeader,
    },
    beforeRouteEnter(to, from, next) {
      const countryCode = to.query.countryCode;
      const inviteId = to.query.inviteId;
      const inviteCode = to.query.inviteCode;
      window.location.href = FixProxyUrl('/signUp?inviteCode=' + inviteCode + '&inviteId=' + inviteId + '&countryCode=' + countryCode);
    },
  })
</script>
