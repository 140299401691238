import { Action, Module, VuexModule } from 'vuex-class-modules';
import { getUser, submitUser } from '../api/endpoints/userWeb';
import { store } from './store';

@Module
class UserProfileModule extends VuexModule {
  @Action
  async loadUser() {
    const result = await getUser();

    if (result != null && !result.userLoadFailed) {
      return result;
    }

    return null;
  }

  @Action
  async saveUser(user: any) {
    const apiResponse = await submitUser(user);

    return apiResponse;
  }
}

export const userProfileModule = new UserProfileModule({
  store,
  name: 'userProfile'
});