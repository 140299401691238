// Custom Formatter implementation based on the BaseFormatter.
// Gets localizer keys based on `%` syntax like the mobile apps do in addition to `{d}` syntax
// If we can get the localizer keys using brace format `{0}` we can discard this.

type Token = {
  type: 'text' | 'list';
  value: string;
}

const RE_END_OF_TOKEN = /[@ds]/;

export function parse(format: string): Token[] {
  const tokens: Token[] = [];
  let position = 0;

  let text = '';
  while (position < format.length) {
    let char: string = format[position++];
    const nextChar: string = format[position];
    if (char === '%') {
      if (text) {
        tokens.push({ type: 'text', value: text })
      }

      text = '';
      let sub = '';
      char = format[position++];
      while (char !== undefined && !RE_END_OF_TOKEN.test(char)) {
        if (char !== '$') // Ignore these
          sub += char;
        char = format[position++];
      }

      if (sub !== '') { // Adjust KVP's starting with `%` having 1 as base index.
        const newVal = parseInt(sub, 10) - 1;
        sub = newVal.toString();
      }

      const type = 'list';
      tokens.push({ value: sub || '0', type })
    }
    else if (char === '{') {
      //if we encounter an empty pair of {} braces, then treat it as a literal character
      if (nextChar === '}') {
        text += char
      } else { //otherwise, look for string interpolation {}
        if (text) {
          tokens.push({ type: 'text', value: text })
        }

        text = '';
        let sub = '';
        char = format[position++];
        while (char !== undefined && char !== '}') {
          sub += char;
          char = format[position++];
        }

        const type = 'list';
        tokens.push({ value: sub, type }) // If index not found, use `1` since that implies there are no other entries.
      }
    }
    else {
      text += char;
    }
  }
  text && tokens.push({ type: 'text', value: text })
  return tokens;
}

export function compile(tokens: Token[], values: string[]): string[] {
  const compiled: string[] = []
  let index = 0

  while (index < tokens.length) {
    const token: Token = tokens[index]

    switch (token.type) {
      case 'text':
        compiled.push(token.value)
        break;
      case 'list':
        compiled.push(values[parseInt(token.value, 10)])
        break;
    }
    index++;
  }

  return compiled;
}

export default class CustomFormatter {
  _caches: { [key: string]: Token[] }

  constructor() {
    this._caches = Object.create(null);
  }

  interpolate(message: string, values: string[]): string[] {
    if (!values) {
      values = [''];
    }
    let tokens: Token[] = this._caches[message]
    if (!tokens) {
      tokens = parse(message)
      this._caches[message] = tokens;
    }
    return compile(tokens, values);
  }
}