<template>
  <footer class="border-top footer text-light ">
    <div id="footerInner">
      &copy; {{new Date().getFullYear()}} Zimmer Biomet  <span class="footerSpace">&nbsp;|&nbsp;</span>
      <span class="footerLinks">
        <a :href="about()">{{ $t('about') }}</a> &nbsp;|&nbsp;
        <a :href="support()">{{ $t('footer_default_support') }}</a>
      </span>
    </div>
  </footer>
</template>

<script lang="ts">
  import * as Vue from 'vue';
  import { store } from '../store/store';

  export default Vue.defineComponent({
    name: 'PageFooter',
    props: {
    },
    components: {

    },
    data() {
      return {
      }
    },
    methods: {
      about: function () {
        if (store.state.aboutUrl != null && store.state.aboutUrl != '')
          return store.state.aboutUrl;

        return '/error?errorKey=unexpected';
      },
      support: function () {
        if (store.state.supportUrl != null && store.state.supportUrl != '')
          return store.state.supportUrl;

        return '/error?errorKey=unexpected';
      }
    }
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
