interface ICountry {
  id: string,
  name: string,
  abbreviation: string,
  databaseRegion: string,
  shardKey: string
}

class Country implements ICountry {
  id = '';
  name = '';
  abbreviation = '';
  databaseRegion = '';
  shardKey = '';
}

export { Country, ICountry };
