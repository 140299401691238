

export function isOnProxy(){
  const apiDomain = process.env.VUE_APP_API_DOMAIN?.toLowerCase();
  return apiDomain!=null && (apiDomain.includes('localhost')||apiDomain.includes('127.0.0.1')) && !apiDomain.includes(location.host)
}

export default function FixProxyUrl(path:string){
  const firstQuerySeperator = !path.includes('?') ? '?' : '&';
  const redirectParam = !path.startsWith('/login') ? '&redirectUri='+encodeURIComponent((process.env.VUE_APP_API_DOMAIN??'')+path) : '';
  return (process.env.VUE_APP_API_DOMAIN ?? '')+path+(isOnProxy()? firstQuerySeperator+'proxy=true'+redirectParam:'');
}
