import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "LinkFederatedUserPage",
  class: "shared-page"
}
const _hoisted_2 = { class: "centeredContentBox" }
const _hoisted_3 = { class: "bottomSpaceDouble header-left" }
const _hoisted_4 = { class: "textLarge bottomSpace" }
const _hoisted_5 = {
  id: "proceedToSignUpWrap",
  class: "buttonWrap"
}
const _hoisted_6 = ["href"]
const _hoisted_7 = {
  class: "button",
  type: "button",
  value: "LinkAccount"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CwePageHeader = _resolveComponent("CwePageHeader")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_CwePageHeader, { authenticationRequired: true }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t('link_account_title')), 1),
        _createElementVNode("div", null, [
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('link_account_instructions')), 1),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("a", {
              href: _ctx.SignUp()
            }, [
              _createElementVNode("button", _hoisted_7, _toDisplayString(_ctx.$t('link_account_button')), 1)
            ], 8, _hoisted_6)
          ])
        ])
      ])
    ])
  ]))
}