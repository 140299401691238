import { getAppConfig } from '@/api/endpoints/appConfigWeb';
import { Action, Module, VuexModule } from 'vuex-class-modules';
import { store } from './store';
import EventBus from '@/services/EventBus';
import { AppConfigResult } from '@/types/webContracts';

@Module
class AppConfigModule extends VuexModule {
	@Action
	async loadAppConfig(): Promise<AppConfigResult> {
		const result = await getAppConfig();
		const success = !result.appConfigFailed;

		if (success) {
			store.state.aboutUrl = result.aboutUrl;
			store.state.supportUrl = result.supportUrl;
			store.state.passwordMinLength = result.passwordMinLength;
			store.state.skipPortalLandingPage = result.skipPortalLandingPage;
      		store.state.federatedTenantRequiredForZBEmployees = result.federatedTenantRequiredForZBEmployees;
			store.state.alwaysShowMigrationMessage = result.alwaysShowMigrationMessage;
			store.state.cookieExpireMinutes = result.cookieExpireMinutes;
    	}

		return result;
	}
}

export const appConfigModule = new AppConfigModule({
	store,
	name: 'appConfig'
});