import { UpdateUserRequest } from '../types/webContracts';

class User implements UpdateUserRequest {
  Email = '';
  FirstName = '';
  LastName = '';
  Prefix = '';
  Suffix = '';
  Phone = '';
  Birthday = '';
  Npi = '';
  PhoneCountryCode = '';
  CountryCode = '';
  FederatedTenant = false;
  PhoneRequired = false;
  __RequestVerificationToken = '';
}

export { User };