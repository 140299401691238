import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import RouteGuard from './guards';
import DashboardPage from '../views/DashboardPage.vue';
import ErrorPage from '../views/ErrorPage.vue';
import MaintenancePage from '../views/MaintenancePage.vue';
import SignUpPage from '../views/SignUpPage.vue';
import PreLoginPage from '../views/PreLoginPage.vue';
import NotFoundPage from '../views/NotFoundPage.vue';
import StartupPage from '../views/StartupPage.vue';
import RedeemInvitePage from '../views/RedeemInvitePage.vue';
import PolicyPage from '../views/PolicyPage.vue';
import RegistrationPoliciesPage from '../views/RegistrationPoliciesPage.vue';
import FindInvitesPage from '../views/FindInvitesPage.vue';
import LinkFederatedUserPage from '../views/LinkFederatedUserPage.vue';
import EditProfilePage from '../views/EditProfilePage.vue';
import ForgotPasswordPage from '../views/ForgotPasswordPage.vue';
import ChangePasswordPage from '../views/ChangePasswordPage.vue';
import ConfirmIdentityPage from '../views/ConfirmIdentityPage.vue';
import RecoverPinPage from '../views/RecoverPinPage.vue';
import { AuthPolicyEnum } from '@/constants/authPolicies';
import { ZBEdgeState } from '@/types/storeTypes';
import { Store } from 'vuex';

// https://www.sitepoint.com/a-beginners-guide-to-vue-router/

const routes = [
  {
    path: '/',
    name: 'StartupPage',
    component: StartupPage,
    meta: {
      isStartupPage: true
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPasswordPage,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/confirm-identity',
    name: 'ConfirmIdentity',
    component: ConfirmIdentityPage,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePasswordPage,
    meta: {
      policies: AuthPolicyEnum.ChangePassword
    }
  },
  {
    path: '/signup',
    name: 'SignUpPage',
    component: SignUpPage,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/prelogin',
    name: 'PreLoginPage',
    component: PreLoginPage,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/edit-profile',
    name: 'EditProfilePage',
    component: EditProfilePage
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardPage
  },
  {
    path: '/redeem-invite',
    name: 'RedeemInvitePage',
    component: RedeemInvitePage,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/policy',
    name: 'PolicyPage',
    component: PolicyPage,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/policies',
    name: 'RegistrationPoliciesPage',
    component: RegistrationPoliciesPage,
    meta: {
      policies: [AuthPolicyEnum.TrustedUser, AuthPolicyEnum.Registration]
    }
  },
  {
    path: '/find-invites',
    name: 'FineInvitesPage',
    component: FindInvitesPage,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/link-user',
    name: 'LinkFederatedUserPage',
    component: LinkFederatedUserPage,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/error/:errorKey?',
    name: 'ErrorPage',
    component: ErrorPage,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/maintenance',
    name: 'MaintenancePage',
    component: MaintenancePage,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/404',
    name: 'NotFoundPage',
    component: NotFoundPage,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFoundPage',
    component: NotFoundPage,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/recover-pin',
    name: 'RecoverPinPage',
    component: RecoverPinPage,
    meta: {
      policies: AuthPolicyEnum.RecoverPin
    }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to:RouteLocationNormalized, from:RouteLocationNormalized, next:NavigationGuardNext) => {
  const result = await RouteGuard(to, from, next);

  const lowerResult = result?.toLowerCase();
  if(result && lowerResult!=to.fullPath?.toLowerCase()){
    if(lowerResult && (lowerResult.includes('http://') || lowerResult.includes('https://') || lowerResult.includes('/logout')))
      window.location.href = result;
    else
      next(result);
  }else next();
});

export default router;