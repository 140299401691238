


export default function FormatPhoneNumber(phoneRaw:string|undefined, countryCode:string|undefined): string {
    
    if(!phoneRaw)
      return '';

    phoneRaw = phoneRaw.trim();
    let formattedPhone = '';

    if(phoneRaw.length>10){

      formattedPhone = phoneRaw.trim();

    } else {   

      const input = phoneRaw.replace(/^a-zA-Z0-9/g,'').substring(0,10);
      const areaCode = input.substring(0,3);
      const middle = input.substring(3,6);
      const last = input.substring(6,10);

      if(input.length > 6)
        formattedPhone = `(${areaCode}) ${middle}-${last}`;
      else if(input.length > 3)
        formattedPhone = `(${areaCode}) ${middle}`;
      else if(input.length > 0)
        formattedPhone = `(${areaCode}`;
    }

    if(countryCode)
      formattedPhone = `+${countryCode} ${formattedPhone}`;
    
    return formattedPhone;
  }
