import axios from 'axios';
import { ApiRoutes } from '../ApiRoutes';
import { AxiosResponse } from 'axios'
import {
  GetPolicyRequest,
  GetUserPoliciesResponse,
  SignUserPoliciesResponse,
  SignUserPoliciesRequest
} from '@/types/webContracts';

export async function GetPolicy(params: GetPolicyRequest): Promise<AxiosResponse<GetUserPoliciesResponse>> {
  const result = await axios.get(ApiRoutes.Auth.GetPolicy + "?policyKey=" + params.policyKey)
    .then(response => {
      return response as AxiosResponse<GetUserPoliciesResponse>;
    })
    .catch(function (error) {
      console.log(error);
      return error.response;
    });
  return result;
}

export async function GetUserPolicies(): Promise<AxiosResponse<GetUserPoliciesResponse>> {
  const cacheBuster = Date.now();
  const result = await axios.get(ApiRoutes.Auth.GetUserPolicies+"?_ts="+cacheBuster)
  .then(response => {
    return response as AxiosResponse<GetUserPoliciesResponse>;
  })
  .catch(function (error) {
    console.log(error);
    return error.response;
  });
  return result;
}

export async function SignUserPolicies(params: SignUserPoliciesRequest): Promise<AxiosResponse<SignUserPoliciesResponse>> {
  const cacheBuster = Date.now();
  const result = await axios.post(ApiRoutes.Auth.SignUserPolicies+"?_ts="+cacheBuster, params)
  .then(response => {
    return response as AxiosResponse<GetUserPoliciesResponse>;
  })
  .catch(function (error) {
    console.log(error);
    return error.response;
  }); 
  return result;
}

