import { Action, Module, VuexModule } from 'vuex-class-modules';
import { getCountries } from '../api/endpoints/countryWeb';
import { store } from './store';
import { CountryResult } from '@/types/webContracts';

@Module
class CountryModule extends VuexModule {
  @Action
  async loadCountries(): Promise<CountryResult> {
    const result = await getCountries();

    return result;
  }
}

export const countryModule = new CountryModule({
  store,
  name: 'country'
});