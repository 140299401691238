import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "maintenance-page",
  class: "shared-page"
}
const _hoisted_2 = { class: "centeredContentBox" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CwePageHeader = _resolveComponent("CwePageHeader")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_CwePageHeader, { authenticationRequired: true }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-triangle-person-digging" }),
        _createElementVNode("p", {
          class: "textLarge bottomSpace",
          innerHTML: _ctx.$t('error_outage_try_later')
        }, null, 8, _hoisted_3)
      ])
    ])
  ]))
}