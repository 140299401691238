


export default function GetCountryFlagUrl(countryCode:string|undefined): string {
  const apiDomain = process.env.VUE_APP_API_DOMAIN?.toLowerCase();

  if (apiDomain == null)
    return `/img/country_flags/${countryCode}.svg`;
  else
    return `${process.env.VUE_APP_API_DOMAIN}/img/country_flags/${countryCode}.svg`;
}
