

import { AuthWorkFlow } from '@/constants/enums';
import { Module, Mutation, VuexModule } from 'vuex-class-modules';
import { store } from './store';

interface SessionState {
  initiatedPasswordResetFlow: boolean;
  policies: string[];
}

@Module
class SessionModule extends VuexModule implements SessionState {
  initiatedPasswordResetFlow = false
  policies: string[] = []

  @Mutation
  setPolicies(policies: string[]){
    this.policies = policies
  }

  @Mutation
  setInitiatedPasswordResetFlow(flag: boolean) {
    this.initiatedPasswordResetFlow = flag
  }

  @Mutation
  clear() {
    this.policies = []
    this.initiatedPasswordResetFlow = false
  }
}

export const sessionModule = new SessionModule({
  store,
  name: 'session'
});