<template>
  <div>
    <CwePageHeader :authenticationRequired="true" />

    <div id="error-page" class="shared-page">
      <div class="centeredContentBox">
        <h3 class="bottomSpace">{{ $t('error') }}</h3>
        <p class="textLarge bottomSpace">{{ $t(errorReason) }}</p>

        <p v-if=(networkError) class="textLarge bottomSpace">{{ $t('error_network') }}</p>

        <a href="/">
          <button class="button" type="button" value="Home">{{ $t('return_home') }}</button>
        </a>
      </div>
    </div>
  </div>

</template>

<script lang="ts">
  import * as Vue from 'vue';
  import CwePageHeader from '../components/CwePageHeader.vue';

  interface IErrorKeys {
    [key: string]: string | undefined;
  }

  var ErrorMessages: IErrorKeys = {
    user_status_load_failed: 'user_status_load_failed',
    app_config_load_failed: 'app_config_load_failed',
    countries_load_failed: 'countries_load_failed',
    forbidden: 'insufficient_permissions',
    timeout: 'session_timeout',
    localization_load_failed: 'localization_load_failed',
    unprocessable_entity: 'unprocessable_entity',
    page_not_found: 'page_not_found',
    unexpected: 'unexpected_error',
    maintenance: 'error_outage_try_later'
  };

  export default Vue.defineComponent({
    name: 'ErrorPage',
    components: {
      CwePageHeader,
    },
    data() {
      return {
        errorReason: '',
        messageKey: '',
        networkError: false
      }
    },
    provide() {
      return {

      }
    },
    created() {
      const urlParams = new URLSearchParams(window.location.search);
      const queryStringErrorKey = urlParams.get('errorKey');
      this.networkError = urlParams.get('networkError')=='true';

      let errorKey = queryStringErrorKey ?? this.$route.params.errorKey.toString();
      errorKey = errorKey.trim().toLowerCase();

      if (errorKey == '' || !errorKey)
        errorKey = 'unexpected';

      if (errorKey != null && ErrorMessages[errorKey])
        this.errorReason = ErrorMessages[errorKey] as string;
      else
        this.errorReason = errorKey;
    }
  })
</script>

<style lang="scss">
  #error-page {
    font-size: $font_size_button_text;
  }
</style>
