<template>
    <div class="phoneNumberLabelRow">
        <div class="phoneNumberLabelColumn">
            <div class="label-bold">
                <label :for="phoneNumber">{{ phoneNumberLabel }}</label>
            </div>
        </div>
    </div>
    <div class="phoneNumberRow">
        <div class="phoneCountryCodeColumn">
            <div class="phoneCountryCode">
                <vue-multiselect id="countryCodeSelect" placeholder="" v-model="selectedCountryCode" :options="phoneCountryCodes" @remove="clearButtonSelected"
                                 track-by="Code" label="Code" :option-height="30" :max-height="300" :show-labels="false" @select="phoneCountryCodeChanged">
                    <template #singleLabel="props">
                        <img v-if="selectedCountryCode?.Code != ''" class="option__image" :src="getFlag(props?.option?.Code)" /> &nbsp;
                        <span class="countryCode">{{ props?.option?.Code }}</span>
                        <template v-if="props.option.Ext != ''">
                            <span class="ext">({{ props?.option?.Ext }})</span>
                        </template>
                    </template>
                    <template #option="props">
                        <img class="option__image" :src="getFlag(props?.option?.Code)" />&nbsp;
                        <span class="countryCode">{{ props?.option?.Code }}</span>
                        <template v-if="props.option.Ext != ''">
                            <span class="ext">({{ props?.option?.Ext }})</span>
                        </template>
                    </template>
                    <template #noResult>
                        {{ noMatchingCountriesLabel }}
                    </template>
                </vue-multiselect>
            </div>
        </div>
        <div class="phoneNumberColumn">
            <input id="phoneNumber"
                   v-bind:value="phoneNumber"
                   v-bind:placeholder="placeholder"
                   v-on:input="phoneChanged($event.target.value)" />
        </div>
    </div>
</template>

<script lang="ts">
  import { CountryCode } from '@/types/phoneCountryCodes';
  import parsePhoneNumber, { CountryCode as CountryCodeEnum, getExampleNumber, isSupportedCountry } from 'libphonenumber-js';
  import GetCountryFlagUrl from '../services/CountryFlag';
  import examples from 'libphonenumber-js/mobile/examples';
  import * as Vue from 'vue';

  export default Vue.defineComponent({
    name: 'PhoneNumberInput',
    props: {
      phoneNumberLabel: {
        type: String,
        required: true,
        default: "Phone Number"
      },
      noMatchingCountriesLabel: {
        type: String,
        required: true,
        default: "Sorry, no matching countries."
      },
      phoneCountryCodes: {
        type: Array,
        required: true,
        default: () => new Array<CountryCode>()
      },
      phoneCountryCode: {
        type: CountryCode,
        required: false,
        default: undefined
      },
      phoneNumber: {
        type: String,
        required: true,
        default: ""
      }
    },
    data() {
      return {
        phone: '',
        placeholder: '',
        selectedCountryCode: this.phoneCountryCode
      }
    },
    watch: {
      phoneCountryCode: function (newVal, oldVal) {
        this.selectedCountryCode = newVal ?? new CountryCode();
      },
      phoneNumber: function (newVal, oldVal) {
        this.phone = newVal;
      }
    },
    mounted: async function () {
      var tempCountryCodes = JSON.parse(JSON.stringify(this.phoneCountryCodes)) as Array<CountryCode>;

      for (var countryCodeIndex = 0; countryCodeIndex < tempCountryCodes.length; countryCodeIndex++) {
        var phoneCountryCode = this.phoneCountryCodes[countryCodeIndex] as CountryCode;

        if (phoneCountryCode && !isSupportedCountry(phoneCountryCode.Code))
          this.phoneCountryCodes.splice(countryCodeIndex, 1);
      }
    },
    created: async function () {
      var loadCounter = 0;

      while (this.selectedCountryCode == undefined) {
        await new Promise(resolve => {
          setTimeout(() => {
            resolve(this.selectedCountryCode != undefined && this.selectedCountryCode.Code.length > 0)
          }, 100);
        }).finally(() => {
          if (this.selectedCountryCode) {
            if (this.selectedCountryCode && this.phone.length > 0) {
                this.phone = this.formatPhoneNumber(this.selectedCountryCode, this.phone);
              }
            this.setPhonePlaceholder(this.selectedCountryCode);
          }

          if (loadCounter == 10)
            return;

          loadCounter++;
        });
      }    
    },
    methods: {
      getFlag(countryCode: string) {
        return GetCountryFlagUrl(countryCode);
        },
      clearButtonSelected() {
        this.selectedCountryCode = undefined;

        if (this.phoneNumber == '') {
          this.placeholder = '';
          this.$emit('updatePhoneNumberError', '');
        }

        this.$emit('updatePhoneCountryCode', new CountryCode());
      },
      phoneCountryCodeChanged(countryCode: CountryCode, id: number) {
        if (this.selectedCountryCode != undefined && this.phone.length > 0) {
          let formattedPhoneNumber = this.formatPhoneNumber(this.selectedCountryCode, this.phone);

          if (formattedPhoneNumber != this.phone) {
            this.phone = formattedPhoneNumber;
            this.$emit('updatePhoneNumber', formattedPhoneNumber);
          }
        }

        this.setPhonePlaceholder(countryCode);
        this.$emit('updatePhoneCountryCode', countryCode);
      },
      phoneChanged(phone: any) {
        if (this.phone.length > 0 && phone.length <= 0 && this.selectedCountryCode == undefined) {
          this.setPhonePlaceholder(new CountryCode());
        }

        if (this.selectedCountryCode != undefined) {
          phone = this.formatPhoneNumber(this.selectedCountryCode, phone);
        }

        this.phone = phone;
        this.$emit('updatePhoneNumber', phone);
      },
      setPhonePlaceholder(countryCode: CountryCode) {
        if (JSON.stringify(countryCode) == JSON.stringify(new CountryCode)) {
          this.placeholder = '';
          return;
        }

        var countryCodeEnum = countryCode.Code as CountryCodeEnum;
        const examplePhoneNumber = getExampleNumber(countryCodeEnum, examples);

        if (examplePhoneNumber) {
          var formatNational = examplePhoneNumber.formatNational();

          if (formatNational && formatNational.length > 0) {
            var placeholder = '';
            
            for (var placeHolderIndex = 0; placeHolderIndex < formatNational.length; placeHolderIndex++) {
              var value = formatNational[placeHolderIndex];

              if (value == ' ' || value == '-') {
                placeholder += ' ';
              }
              else if (value == '(' || value == ')') {
                placeholder += '';
              }
              else {
                placeholder += "x";
              }
            }

            this.placeholder = placeholder;
          }
        }
      },
      formatPhoneNumber(countryCode: CountryCode, phoneNumber: string) {
        if (JSON.stringify(countryCode) == JSON.stringify(new CountryCode))
          return phoneNumber;

        var countryCodeEnum = countryCode.Code as CountryCodeEnum;
        let fullNumber = '+' + countryCode.Ext + phoneNumber;
        let parsedPhoneNumber = parsePhoneNumber(fullNumber, countryCodeEnum);

        return parsedPhoneNumber
          ? parsedPhoneNumber.formatNational()
          : phoneNumber;
      }
    },
  })
</script>

<style lang="scss">
  .multiselect, multiselect--active {
    max-height: $standard_space * 2;
  }

  .multiselect__option--highlight {
    background: $primary_button_color;
  }

  .multiselect__content {
    min-width: 100%;
    width: 6rem;
  }

  .multiselect__single {
    white-space: nowrap;
  }

  .multiselect__content-wrapper{
    min-width: 100%;
    width: 8rem;
  }

  .option__image {
    max-width: 24px;
  }

  .phoneNumberLabelRow {
    display: flex;
    max-width: 350px;
  }

  .phoneNumberLabelRow > .phoneNumberLabelColumn {
    flex: 1 1 1;
    min-width: 150px;
    max-width: 175px;
    margin-right: 4px;
  }

  .phoneNumberLabelRow > .phoneNumberLabelColumn {
    flex: 1 1 2;
  }

  .phoneNumberRow {
    max-height: $standard_space * 2;
    display: flex;
    max-width: 350px;
  }

  .phoneNumberRow > .phoneCountryCodeColumn {
    flex: 1 1 1;
    min-width: 9rem;
    max-width: 9rem;
    margin-right: 4px;

    .countryCode {
      color: $primary_text;
    }

    .ext {
      font-size: 0.8rem;
      color: $faint_text;
      padding-left: 2px;
    }
  }

  .phoneNumberRow > .phoneNumberColumn {
    width: 100%;

    @media screen and ( max-width: 500px ) {
      
      input {  
        max-width: 150px;
      }
    }
  }
</style>
