<template>
  <div>
    <CwePageHeader :authenticationRequired="true" />

    <div id="FindInvitesPage" class="shared-page">
      <div class="centeredContentBox">
        <div class="back-section">
          <div class="back-section-inner">
            <a v-if="currentStep == 'enterPhonePin' && phoneProvided == true" @click="back()">
              <font-awesome-icon icon="fa-solid fa-angle-left"></font-awesome-icon>
            </a>
          </div>
        </div>

        <h3 class="bottomSpaceDouble header-left">{{ $t('redeem_invite_title') }}</h3>

        <div v-if="pageErrorMessage">
          <p class="alertBox textLarge bottomSpaceDouble">{{ pageErrorMessage }}</p>
        </div>


        <div v-if="currentStep=='collectEmailPhone'">
          <p class="textLarge bottomSpace">{{ $t('find_invites_email_phone_instructions') }}</p>

          <form id="ValidatePhoneForm" @submit="SubmitEmailPhone">
              <div class="section">
                  <div class="label-bold">
                      <label :for="email">{{ $t('email_address') }}</label>
                  </div>
                  <div>
                      <input id="email" v-model="email" maxlength="100" type="email" />
                  </div>
                  <div class="error" v-if="emailError.length">{{ emailError }}</div>
              </div>

              <div class="section bottomSpaceDouble">
                  <PhoneNumberInput :phoneNumberLabel="this.$t('mobile_number')"
                                    :noMatchingCountriesLabel="this.$t('no_matching_countries')"
                                    :phoneCountryCodes="this.phoneCountryCodes"
                                    :phoneCountryCode="this.selectedCountryCode"
                                    :phoneNumber="this.phone"
                                    @updatePhoneCountryCode="phoneCountryCodeChanged($event)"
                                    @updatePhoneNumber="phoneNumberChanged($event)"
                                    @updatePhoneNumberError="phoneNumberErrorChanged($event)" />

                  <div class="error" v-if="phoneError.length">{{ phoneError }}</div>
              </div>

              <div class="button-section-right">
                  <button class="button-right"
                          type="submit"
                          value="EmailPhoneFormSubmit">
                      {{ $t('submit') }}
                  </button>
                  <button class="button-right go-back"
                          type="button"
                          @click="goBack">
                      {{ $t('go_back') }}
                  </button>
              </div>
          </form>
        </div>


        <div v-if="currentStep=='enterPhonePin'">
          <p class="textLarge bottomSpace">{{ getVerificationCodeInstructions() }}</p>

          <form id="ValidatePhoneForm" @submit="ValidatePhonePin">
            <div class="section">
              <div>
                <input id="phonePin" v-model="phonePin" maxlength="6" type="text" placeholder="xxxxxx" />
              </div>
            </div>

            <p @click="resendInvite" class="textLarge bottomSpaceDouble" v-html="getVerificationCodeHtml()"></p>

            <div class="button-section-right">
              <button class="button-right" type="submit" value="ValidatePhonePin">{{ $t('redeem_invite_validate_phone_button') }}</button>
              <button class="button-right go-back" type="button" @click="goBack"> {{ $t('go_back') }} </button>
            </div>
          </form>
        </div>


        <div v-if="currentStep=='chooseInvite'">
          <p class="textLarge bottomSpace">{{ $t('find_invites_choose_invite_instructions') }}</p>

          <ul id="matchingInvites">
            <li v-for="invite in pendingInvites" :key="invite.inviteID" @click="LoadInvite(invite)">
              <b>{{FormatClientName(invite.clientName)}}</b>{{FormatApplicationNames(invite.applicationNames)}}
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>

</template>

<script lang="ts">
  import axios from 'axios';
  import * as EmailValidator from 'email-validator';
  import * as Vue from 'vue';
  import { ApiRoutes } from '../api/ApiRoutes';
  import { IStandardResponse } from '../models/StandardResponse';
  import ErrorUrlFromStatusCode from '../services/ErrorUrlFromStatusCode';
  import CwePageHeader from '../components/CwePageHeader.vue';
  import PhoneNumberInput from '../components/PhoneNumberInput.vue';
  import { CountryCode, PhoneCountryCodes } from '@/types/phoneCountryCodes';
  import parsePhoneNumber, { CountryCode as CountryCodeEnum, validatePhoneNumberLength, ValidatePhoneNumberLengthResult } from 'libphonenumber-js';
  import { VerificationMode } from '../constants/enums';

  interface IPendingInvite {
    inviteID: number;
    inviteCode: string;
    clientName: string;
    applicationNames: Array<string>;
  }

  interface IFindInvitesResponse extends IStandardResponse {
    pendingInvites: Array<IPendingInvite>;
    invalidPin: boolean | undefined | null;
    phone: string;
    phoneCountryCode: string;
  }

  interface FindInvitesData {
    currentStep: string;
    email: string;
    emailError: string,
    phone: string;
    phoneCountryCodes: CountryCode[],
    phoneCountryCode: string,
    selectedCountryCode: CountryCode | undefined,
    phoneError: string,
    phonePin: string | undefined,
    invalidPhonePin: boolean,
    initiated: boolean,
    pageErrorMessage: string | undefined,
    pendingInvites: Array<IPendingInvite>,
    verificationMode: VerificationMode,
  }

  export default Vue.defineComponent({
    name: 'FindInvitesPage',
    components: {
      CwePageHeader,
      PhoneNumberInput,
    },
    data(): FindInvitesData {
      return {
        currentStep: 'collectEmailPhone',
        email: '',
        emailError: '',
        phone: '',
        phoneError: '',
        phoneCountryCodes: PhoneCountryCodes.sort((a, b) => a.Code > b.Code ? 1 : b.Code > a.Code ? -1 : 0),
        phoneCountryCode: '',
        selectedCountryCode: undefined as CountryCode | undefined, 
        phonePin: '',
        invalidPhonePin: false,
        initiated: false,
        pageErrorMessage: undefined,
        pendingInvites: [],
        verificationMode: VerificationMode.sms,
      }
    },
    methods: {
      back: function () {
        if (this.currentStep == 'enterPhonePin')
          this.currentStep = 'collectEmailPhone';
      },
      phoneCountryCodeChanged(countryCode: CountryCode) {
        this.phoneCountryCode = countryCode.Ext;
        this.selectedCountryCode = countryCode;
      },
      phoneNumberChanged(phone: any) {
        this.phone = phone;
      },
      phoneNumberErrorChanged(error: any) {
        this.phoneError = error;
      },
      handleVerificationCodeRequestEvent(e: Event) {
        e.preventDefault();

        if (e.target && e.target as Element) {
          var element = e.target as Element;

          if (element.classList.contains("verifyWithEmail")) {
            this.verificationMode = VerificationMode.email;
          }
          else if (element.classList.contains("verifyWithSMS")) {
            this.verificationMode = VerificationMode.sms;
          }
        }
      },
      getVerificationCodeInstructions() {
        if (this.verificationMode === VerificationMode.email) {
          return this.$t("validate_email_instructions_if_correct", [this.email]);
        }
        else if (this.verificationMode === VerificationMode.sms) {
          if (this.phone == null || this.phone.length == 0) {
            return this.$t("validate_phone_instructions_if_correct");
          }

          return this.$t("validate_phone_instructions_if_correct_with_number", [this.phone])
        }
      },
      getVerificationCodeHtml() {
        let params = [
          '<span class="a-span tryAgain">',
          '</span>',
        ];

        if (this.verificationMode === VerificationMode.email) {
          if (this.phone == null || this.phone.length == 0) {
            return this.$t('redeem_invite_validate_phone_didnt_receive', params).replace(",", "")
          }

          params.push('<span class="a-span verifyWithSMS">', '</span>')
          return this.$t('redeem_invite_validate_email_didnt_receive_or_verify_with_sms', params)
        }
        else if (this.verificationMode === VerificationMode.sms) {
          params.push('<span class="a-span verifyWithEmail">', '</span>')
          return this.$t('redeem_invite_validate_phone_didnt_receive_or_verify_with_email', params)
        }
      },
      goBack: function () {
        this.$router.back();
      },
      ValidatePhonePin: async function (e: any) {
        e.preventDefault();

        this.ClearErrors();
        this.SetErrors();

        if (this.ErrorsExist())
          return;

        this.phonePin = this.phonePin?.trim();

        if (this.phonePin == null || this.phonePin.length < 6) {
	      this.pageErrorMessage = this.$t('validate_2fa_pin_invalid');
          return;
        }

        this.$store.state.loadingCount++;

        var query = `?email=${encodeURIComponent(this.email)}&phone=${encodeURIComponent(this.phone)}&phoneCountryCode=${encodeURIComponent(this.phoneCountryCode)}&phonePin=${encodeURIComponent(this.phonePin)}`;

        axios.get(ApiRoutes.User.FindInvites + query).then((response) => {

          const findInvitesResponse = response.data as IFindInvitesResponse;

          if(findInvitesResponse.invalidPin)
          {
			this.pageErrorMessage = this.$t('validate_2fa_pin_invalid');
          } 
          else if (findInvitesResponse.pendingInvites == null || findInvitesResponse.pendingInvites.length == 0) {
            this.pageErrorMessage = this.$t('find_invites_none_found');
          }
          else if (findInvitesResponse.pendingInvites.length == 1) {
            var invite = findInvitesResponse.pendingInvites[0];
            this.$router.push({ name: 'RedeemInvitePage', query: { id: invite.inviteID, code: invite.inviteCode } });
          }
          else if (findInvitesResponse.pendingInvites.length > 1) {
            this.pendingInvites = findInvitesResponse.pendingInvites;
            this.currentStep = 'chooseInvite';
          }

        })
          .catch((error) => {
            const findInvitesResponse = error.response?.data as IFindInvitesResponse;

            if ((error?.response?.status == 422 || error?.response?.status == 400) && findInvitesResponse?.errors?.length > 0) {
              this.pageErrorMessage = findInvitesResponse.errors[0].localizedName;
            } else {
              window.location.href = ErrorUrlFromStatusCode(error?.response?.status);
            }
          })
          .finally(() => {
            this.$store.state.loadingCount--;
          });
      },
      resendInvite: function (e: any) {
        if (e) {
          this.handleVerificationCodeRequestEvent(e);
        }

        this.SubmitEmailPhone(null, true);
      },
      SubmitEmailPhone: async function (e: any, refreshPin: boolean) {
        if (e) {
          e.preventDefault();
        }

        refreshPin = refreshPin == true;

        this.email = this.email?.trim();
        this.phone = this.phone?.trim();
        this.verificationMode = this.phone.length == 0 ? VerificationMode.email : this.verificationMode;

        this.ClearErrors();
        this.SetErrors();

        if (this.ErrorsExist())
          return false;

        this.$store.state.loadingCount++;

        var query = `?email=${encodeURIComponent(this.email)}&refreshPin=${refreshPin}&phone=${encodeURIComponent(this.phone)}&phoneCountryCode=${encodeURIComponent(this.phoneCountryCode)}&verificationMode=${this.verificationMode}`;

        axios.get(ApiRoutes.User.FindInvites + query).then((response) => {

          const findInvitesResponse = response.data as IFindInvitesResponse;

          if ((findInvitesResponse.pendingInvites == null || findInvitesResponse.pendingInvites.length == 0) && this.phonePin != null && this.phonePin != '') {
            this.pageErrorMessage = this.$t('find_invites_none_found');
          }
          else {
            this.currentStep = 'enterPhonePin';
          }
        })
          .catch((error) => {
            const findInvitesResponse = error.response?.data as IFindInvitesResponse;

            if ((error?.response?.status == 422 || error?.response?.status == 400) && findInvitesResponse?.errors?.length > 0) {
              this.pageErrorMessage = findInvitesResponse.errors[0].localizedName;
            } else {
              window.location.href = ErrorUrlFromStatusCode(error?.response?.status);
            }
          })
          .finally(() => {
            this.$store.state.loadingCount--;
          });
      },
      ClearErrors: function () {
        this.emailError = '';
        this.phoneError = '';
        this.pageErrorMessage = '';
      },
      SetErrors: function () {
        if (!this.email)
          this.emailError = this.$t('required_email');
        else if (EmailValidator.validate(this.email) == false) {
          this.emailError = this.$t('required_email');
        }

        // Ensure if the user selects a country, they have to enter a phone number
        if ((this.phoneCountryCode == null || this.phoneCountryCode.length == 0) && !this.selectedCountryCode && this.phone != null && this.phone.length > 0)
          this.phoneError = this.$t('invalid_phone_number');
        // Ensure if the user enters a phone number, they have to select a country
        else if ((this.phone == null || this.phone.length == 0) && this.phoneCountryCode != null && this.phoneCountryCode.length > 0 && this.selectedCountryCode)
          this.phoneError = this.$t('invalid_phone_number');

        if (this.selectedCountryCode) {
          var countryCodeEnum = this.selectedCountryCode.Code as CountryCodeEnum;
          var parsedPhoneNumber = parsePhoneNumber("+" + this.selectedCountryCode.Ext + this.phone, countryCodeEnum);

          if (parsedPhoneNumber) {
            var validPhoheNumber = parsedPhoneNumber.isValid();
            var validPhoneNumberLength = validatePhoneNumberLength("+" + this.selectedCountryCode.Ext + this.phone, countryCodeEnum) as ValidatePhoneNumberLengthResult;

            if (validPhoheNumber == false || validPhoneNumberLength == 'TOO_LONG')
              this.phoneError = this.$t('invalid_phone_number');
          }
        }
      },
      ErrorsExist: function () {
        return this.emailError || this.phoneError;
      },
      FormatApplicationNames: function (applicationNames: Array<string>) {
        if (!applicationNames) return '';
        return applicationNames.join(', ');
      },
      FormatClientName: function (clientName: string | undefined) {
        if (!clientName) return '';
        return clientName + ' ';
      },
      LoadInvite: function (invite: IPendingInvite) {
        this.$router.push({ name: 'RedeemInvitePage', query: { id: invite.inviteID, code: invite.inviteCode } });
      }
    },
    mounted() {

    }
  })
</script>

<style lang="scss">
  .buttonWrap {
    margin-bottom: $standard_space;
  }

  .back-section {
    position: relative;

    .back-section-inner {
      position: absolute;
      top: 15px;
      left: -80px;
      cursor: pointer;
    }

    @media screen and ( max-width: 900px ) {
      .back-section-inner {
        display: none;
      }
    }

    .fa-angle-left {
      font-size: 18pt;
      color: $dark_accent_color;
    }
  }

  button {
    min-width: 350px;
  }

  #FindInvitesPage {
    display: flex;

    p {
      text-align: left;
    }

    .phoneNumberRow > .phoneCountryCodeColumn {
      width: 5rem;
    }

    .multiselect__tags, #phoneNumberInput {
      background: $very_light_gray;
      border: 0px;
      border-radius: 6px;
    }

    .multiselect__single {
      background: $very_light_gray;
    }

    .alertBox {
      text-align: center;
    }

    input {
        height: 2.5rem;
    }

    #email, #phonePin {
      width: 100%;
    }
  }

  ul#matchingInvites {
    list-style: none;
    margin: $standard_space 0;
    padding: 0;
    text-align: left;
    border: 1px solid $gray;
    background: $very_light_gray;

    li {
      padding: $standard_space/2;
      margin: 0;
      border-bottom: 1px solid $gray;
      cursor: pointer;

      &:hover {
        background: #ededed;
      }

      &:last-child {
        border-bottom: 0 none;
      }
    }
  }
</style>
