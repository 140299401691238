import axios from 'axios';
import { ApiRoutes } from '../ApiRoutes';
import { AxiosResponse } from 'axios'
import {
  SendActivationCodeRequest,
  SendActivationCodeResponse,
  ValidateActivationCodeRequest, 
  ValidateActivationCodeResponse,
} from '@/types/webContracts';

export async function sendVerificationCode( params: SendActivationCodeRequest ): Promise<AxiosResponse<SendActivationCodeResponse>> {
  const result = await axios.post(ApiRoutes.Auth.SendVerificationCode, params);
  return result;
}

export async function validateVerificationCode(params: ValidateActivationCodeRequest ): Promise<AxiosResponse<ValidateActivationCodeResponse>> {
  const result = await axios.post(ApiRoutes.Auth.ValidateVerificationCode, params);

  return result;
}
