<template>
    <div class="dashboard">
        <CwePageHeader :authenticationRequired="false"/>
        <div id="MainDashboard">
            <div v-if="usersApps.length == 0" class="bottomSpaceDouble">
                {{ $t('no_apps_enabled_for_your_account') }}
            </div>
                
            <div class="myApps">
                <h5 class="sectionHeader">{{ $t('my_zbedge_apps') }}</h5>
                <div v-if="usersApps.length > 0" class="appsRow">
                    <AppListItem v-for="app in usersApps" :key="app.name" v-bind:app="app" />
                </div>
            </div>
                
            <div class="moreApps" v-if="moreApps.length > 0">
                <h5 class="sectionHeader">{{ $t('more_apps_to_consider') }}</h5>
                <div v-if="moreApps.length > 0" class="moreAppsRow">
                    <MoreAppListItem v-for="app in moreApps" :key="app.name" v-bind:app="app" />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import * as Vue from 'vue';
import AppListItem from '../components/AppListItem.vue';
import MoreAppListItem from '../components/MoreAppListItem.vue';
import CwePageHeader from '../components/CwePageHeader.vue';
import AppRedirectService from '../services/AppRedirectService';
import { store } from '../store/store';

export default Vue.defineComponent({
    name: 'DashboardPage',
    components: {
        CwePageHeader,
        AppListItem,
        MoreAppListItem
    },
    beforeRouteEnter(to, from, next) {
        let apps = store.state.apps;
        var appRedirectExists = store.state.appRedirect && store.state.appRedirect.length > 0;
        var appEnabledCount = 0;
        var appVisibleCount = 0;
        var appEnabledIndex = 0;
        var appRedirectUrl = '';
        var appRedirectKey = '';
        var path = to.redirectedFrom?.query?.path?.toString();

        for (var appIndex = 0; appIndex < apps.length; appIndex++) {
            var app = apps[appIndex];
            if (app.enabled) {
                appEnabledCount++;
                appEnabledIndex = appIndex;

                if(app.visibleOnDashboard) {
                    appVisibleCount++;
                }

                if (store.state.appRedirect == app.key) {
                    appRedirectUrl = app.appRedirectUrl;
                    appRedirectKey = app.key;
                }
            }
        }

        // This is for the auto redirect if only 1 app is enabled
        // OR if there is only 1 visible app, redirect if the path was not set
        /*
		if ((appRedirectExists == false && appEnabledCount == 1) 
            || (appEnabledCount > 1 && appVisibleCount == 1 && path === undefined)) {
            let app = apps.find(a => a.enabled && a.visibleOnDashboard);
            appRedirectUrl = app?.appRedirectUrl ?? '';
            appRedirectKey = app?.key ?? '';
        }
        */

        if (appRedirectUrl.length > 0 && appRedirectKey.length > 0) {
            if (appRedirectExists) {
                AppRedirectService.Go(appRedirectUrl, appRedirectKey, false, path);
                return;
            }

            AppRedirectService.Go(appRedirectUrl, appRedirectKey, appVisibleCount > 1, path);
            return;
        }

        next();
    },
    data() {
        return {};
    },
    computed: {
        usersApps() {
            return store.state.apps.filter(app => app.visibleOnDashboard);
        },
        moreApps() {
            return store.state.moreApps;
        }
    },
    methods: {},
    created: function () {
        
    }
});
</script>

<style lang="scss">
.dashboard {

  // Put all default and desktop 1200px+ view styles here  

  background-image: url('/public/img/zbedge-diamonds-background.png');
  background-size: cover;
  background-position: center;
  //min-height: calc(100vh - 50px);
  display:flex;
  flex:2;
  flex-direction:column;
  justify-content:flex-start;

  #MainDashboard {
    width:$maxInnerPageWidth;
    margin:auto;
	display: flex;
    flex:2;
	flex-direction: column;
	align-items: center;
    padding-inline:0;
  }

  .sectionHeader {
	padding-top: $standard_space;
    padding-bottom:4px;
    margin-bottom: 1rem;
	align-self: flex-start;
    border-bottom:1px solid $medium_light_gray;
  }

  .myApps { padding-inline:0; }

  .myApps, .moreApps {
    align-content: space-between;
    width:100%;

    .appsRow, .moreAppsRow {
      display:flex; 
      align-content: flex-start; 
      justify-content: flex-start; 
      flex-wrap: wrap;
      width: auto;
      column-gap: 1.9%;
    }

    .AppListItem, .MoreAppListItem  {
      width: 31.9%;
      margin-bottom:$standard_space;
      &:nth-child(3n+3) { margin-left:auto; }
    }
  }

  .moreApps {
    background: $white;
    padding-inline: 2%;
    width:100%;
  }


  // Smaller Screensizes, becoming incrementally smaller, only override what's changing 

  @media screen and (max-width: 1250px) {

    #MainDashboard { 
        padding-inline:0; 
        width:100%; 
        max-width:unset; 
    }

    .myApps { padding-inline:2%; }

    .myApps, .moreApps {
      column-gap: 2%;


      .moreAppsRow, .appsRow  {
        column-gap: 2%;
        justify-content:space-between;
      }

      .AppListItem, .MoreAppListItem  {
        width: 49%;
        margin-left:unset;
        &:nth-child(3n+3) { margin-left:unset; }
      }
    }

    .moreApps {
      flex:2;
    }
  }

  @media screen and ( max-width: 660px ) {
    .dashboard {
      min-height: calc(100vh - 76px);
    }
  }

  @media all and (max-width: 575px) {
    .moreApps,
    .myApps { 
      justify-content: center;
      display:block; 
      .moreAppsRow, .appsRow  {
        column-gap: 2%;
        justify-content: center;
        display:block; 
        .MoreAppListItem,
        .AppListItem { 
          width: auto;
          display:block; 
        }
      }
    }
  }


}
</style>